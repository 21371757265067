import React, { useState } from 'react';
import { FileDto } from '../../api/useFileSystem';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import FormHelperText from '@mui/joy/FormHelperText';
import { RiskItemDto } from '../../api/api.generated.clients';
import { useDeleteRisk } from '../../api/useRisks';

export interface IDeleteRiskDialog{
    projectId: string,    
    risk: RiskItemDto,
    isOpen: boolean,
    onClose: ()=>void
}

export default function(props: IDeleteRiskDialog){
    const [formError, setFormError] = useState<string>();
    const deleteRisk = useDeleteRisk();

    function onDelete(){    
        async function doWork(){
            if(props.risk.id){
                await deleteRisk(props.risk.id!);
                props.onClose();
            }
            else    
                setFormError("The risk has not been saved");
        }
        doWork();
    }

    return (
        <Modal open={props.isOpen} onClose={props.onClose}>
            <ModalDialog onClick={(e)=>e.stopPropagation()}>
                <Stack direction={'column'} gap={3}>
                    <Typography>Do you want to delete risk {props.risk?.referenceNumber}?</Typography>                    
                    <Button onClick={()=> onDelete()}>Delete</Button>
                    {!!formError && <FormHelperText>
                                {formError}
                            </FormHelperText>}
                </Stack>
            </ModalDialog>
        </Modal>
    )
}