import { RiskItemDto } from "../../api/api.generated.clients";
import { RiskScore } from "./ScoreEvaluation";

function GetRiskColour(risk: RiskItemDto) {
    if (!risk.likelihoodScore || !risk.consequenceScore)
        return undefined;

    const score = new RiskScore(risk.likelihoodScore, risk.consequenceScore)
    let colour = 'green';
    switch (score.OverallSeverity()) {
        case 1:
            colour = 'green';
            break;
        case 2:
            colour = 'yellow';
            break;
        case 3:
            colour = 'orange';
            break;
        case 4:
            colour = 'red'
            break;

    }
    return colour;
}

function GetRiskSeverityLabel(risk: RiskItemDto){
    if (!risk.likelihoodScore || !risk.consequenceScore)
        return undefined;

    let label = 'Low';
    const score = new RiskScore(risk.likelihoodScore, risk.consequenceScore)
    switch (score.OverallSeverity()) {
        case 1:
            label = 'Low';
            break;
        case 2:
            label = 'Medium';
            break;
        case 3:
            label = 'High';
            break;
        case 4:
            label = 'Extreme'
            break;

    }

    return label;
}

function GetDisciplines(): IDiscipline[] {
    return [
        { value : "acoustic", Label: "Acoustic" },
        { value : "building architectural", Label: "Building Architectural" },
        { value : "bridges", Label: "Bridges" },
        { value : "drainage", Label: "Drainage" },
        { value : "environmental", Label: "Environmental" },
        { value : "electrical services", Label: "Electrical Services" },
        { value : "earthworks", Label: "Earthworks" },
        { value : "fencing", Label: "Fencing" },
        { value : "general", Label: "General" },
        { value : "geotechnical", Label: "Geotechnical" },
        { value : "land acquisitions", Label: "Land Acquisitions" },
        { value : "landscape design", Label: "Landscape Design" },
        { value : "local road works", Label: "Local Road Works" },
        { value : "maintenance", Label: "Maintenance" },
        { value : "n/a", Label: "N/A" },
        { value : "noise mitigation", Label: "Noise Mitigation" },
        { value : "public utility plant", Label: "Public Utility Plant" },
        { value : "pavement", Label: "Pavement" },
        { value : "property works", Label: "Property Works" },
        { value : "roads", Label: "Roads" },
        { value : "road furniture", Label: "Road Furniture" },
        { value : "road lighting", Label: "Road Lighting" },
        { value : "general reports", Label: "General Reports" },
        { value : "retaining walls", Label: "Retaining Walls" },
        { value : "shop drawings", Label: "Shop Drawings" },
        { value : "sketches", Label: "Sketches" },
        { value : "signs and lines", Label: "Signs and Lines" },
        { value : "specifications", Label: "Specifications" },
        { value : "structures", Label: "Structures" },
        { value : "systems", Label: "Systems" },
        { value : "traffic control plans", Label: "Traffic Control Plans" },
        { value : "typical details", Label: "Typical Details" },
        { value : "traffic", Label: "Traffic" },
        { value : "traffic staging", Label: "Traffic Staging" },
        { value : "temporary works", Label: "Temporary Works" },
        { value : "urban design", Label: "Urban Design" },
        { value : "miscellaneous", Label: "Miscellaneous" }
    ]
}

export interface IDiscipline {
    value: string,
    Label: string
}


function GetPhases(): IPhase[] {
    return [
        { value: 'design', Label: 'Design' },
        { value: 'construction', Label: 'Construction' },
        { value: 'maintainance', Label: 'Maintainance' },
        { value: 'operation', Label: 'Operation' },
        { value: 'demolition', Label: 'Demolition' },
        { value: 'other', Label: 'Other' },
    ]
}

export interface IPhase {
    value: string,
    Label: string
}

function GetSeverities(): string[]{
    return [
        'Low',
        'Medium',
        'High',
        'Extreme'
    ]
}

export { GetRiskColour, GetDisciplines, GetPhases, GetRiskSeverityLabel, GetSeverities }