import React from 'react';
import GoogleMap from '../components/Maps/GoogleMap';
import Stack from '@mui/joy/Stack';
import { useParams } from 'react-router-dom';
import { ProjectDto } from '../api/api.generated.clients';
import Drawer from '../components/Drawer/Drawer';
import Tabs from '@mui/joy/Tabs';
import Tab from '@mui/joy/Tab';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import styled from '@emotion/styled';
import Card from '@mui/joy/Card';
import Box from '@mui/joy/Box';
import MapIcon from '@mui/icons-material/Map';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import FlagIcon from '@mui/icons-material/Flag';
import RecyclingIcon from '@mui/icons-material/Recycling';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import FileView from '../components/Projects/v2/FlexiFileView';
import ProjectSettingsButton from '../components/Projects/ProjectSettingsButton';
import OpportunitiesView from '../components/Sustainability/OpportunitiesView';
import { Typography, Button, Select, Option } from '@mui/joy';
import RiskToolbar from '../components/Projects/v2/RiskToolbar';
import OpportunitiesToolbar from '../components/Projects/v2/OpportunitiesToolbar';
import { useGetProject } from '../api/useProjects';
import RisksView from '../components/Projects/v2/RisksView';
import MapView from '../components/Projects/v2/MapView';
import ProjectFeatureLayout from '../components/Projects/v2/ProjectFeatureLayout';






export default function ProjectView2() {

    const { id } = useParams();
    const projectQuery = useGetProject(id ?? '');
    const [project, setProject] = React.useState<ProjectDto>();
    const [selectedPhaseId, setSelectedPhaseId] = React.useState<string>();

    React.useEffect(() => {
        if (project && project.phases && project.phases.length > 0) {
            setSelectedPhaseId(project.phases[0].id)
        }
    }, [project]);

    React.useEffect(() => {
        if (!projectQuery.isPending && !projectQuery.isError && projectQuery.data) {
            setProject(projectQuery.data)
        }
    }, [projectQuery.isPending, projectQuery.isError, projectQuery.data]);

    function onUpdatePhaseId(phaseId: string) {
        console.log('still updating phase id: ' + phaseId)
        setSelectedPhaseId(phaseId)
    }

    if (!id)
        return <>no id</>

    if (!project)
        return <>no project</>


    return (
        // <Stack direction={'column'} justifyContent={'stretch'} sx={{  maxHeight: '100%' }}>
        <Tabs variant='plain' color='primary' sx={{ height: '100%', margin: '5px' }}>
            <TabList>
                <Stack direction={'row'} sx={{width: '100%'}}>
                    <Stack direction={'row'} sx={{flexGrow:1}}>
                        <Tab variant='soft' color='primary'><Typography level={'title-lg'}>Safety In Design</Typography></Tab>
                        <Tab variant='soft' color='primary'><Typography level={'title-lg'}>Sustainability In Design</Typography></Tab>
                        <Tab variant='soft' color='primary'><Typography level={'title-lg'}>Design Review</Typography></Tab>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} gap={2}>
                        <Typography level={'title-md'}>Project Name:</Typography>
                        <Typography level={'title-sm'}>{project.projectName}</Typography>
                        <ProjectSettingsButton projectId={id} />
                    </Stack>
                </Stack>
            </TabList>
            <TabPanel value={0}>
                <ProjectFeatureLayout
                    project={project}
                    onPhaseIdChanged={onUpdatePhaseId}
                    renderDataView={() => <RisksView phaseId={selectedPhaseId ?? ''} project={project} />}
                    renderFileView={() => <FileView phaseId={selectedPhaseId ?? ''} project={project} Toolbar={RiskToolbar} />}
                    renderMapView={() => <MapView phaseId={selectedPhaseId ?? ''} project={project} Toolbar={RiskToolbar} />}
                />
            </TabPanel>
            <TabPanel value={1}>
                <ProjectFeatureLayout
                    project={project}
                    onPhaseIdChanged={onUpdatePhaseId}
                    renderDataView={() => <OpportunitiesView phaseId={selectedPhaseId ?? ''} project={project} />}
                    renderFileView={() => <FileView phaseId={selectedPhaseId ?? ''} project={project} Toolbar={OpportunitiesToolbar} />}
                    renderMapView={() => <MapView phaseId={selectedPhaseId ?? ''} project={project} Toolbar={OpportunitiesToolbar} />}
                />
            </TabPanel>
            <TabPanel value={2}>
                <Typography>Design Review</Typography>
            </TabPanel>
        </Tabs>
    )
}