import React from 'react';
import Typography from '@mui/joy/Typography';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FileUploadDialog from './FileUploadDialog';
import CreateFolderDialog from './CreateFolderDialog';
import { FolderDto } from '../../api/useFileSystem';
import MenuButton from '@mui/joy/MenuButton';
import { Dropdown } from '@mui/joy';
import DeleteFolderDialog from './DeleteFolderDialog';
import RenameFolderDialog from './RenameFolderDialog';

export interface IFolderContextMenuProps {
    phaseId: string,
    folder: FolderDto
}

export default function (props: IFolderContextMenuProps) {
    const [isDeleteFolderDialogOpen, setDeleteFolderDialogOpen] = React.useState<boolean>(false);
    const [isRenameFolderDialogOpen, setRenameFolderDialogOpen] = React.useState<boolean>(false);

    return <>
        <Dropdown>
            <MenuButton variant='plain' size='sm' onClick={(e)=> e.stopPropagation()}>
                <MoreHorizIcon/>
            </MenuButton>
            <Menu>
                <MenuItem onClick={(e) => {setDeleteFolderDialogOpen(true); e.stopPropagation()}}><Typography>Delete Folder</Typography></MenuItem>            
                <MenuItem onClick={(e) => {setRenameFolderDialogOpen(true); e.stopPropagation()}}><Typography>Rename Folder</Typography></MenuItem>            
            </Menu>
        </Dropdown>
        <DeleteFolderDialog folder={props.folder} isOpen={isDeleteFolderDialogOpen} onClose={()=> setDeleteFolderDialogOpen(false)} phaseId={props.phaseId}/>
        <RenameFolderDialog folder={props.folder} isOpen={isRenameFolderDialogOpen} onClose={()=> setRenameFolderDialogOpen(false)} phaseId={props.phaseId} />
    </>
}