import React from 'react';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Input from '@mui/joy/Input';
import { useSaveFolder, FileParameter, SaveFolderCommand, FolderDto } from '../../api/useFileSystem';


export interface IRenameFolderDialogProps {
    isOpen: boolean,
    phaseId: string,
    folder: FolderDto,
    onClose: () => void,
}

export default function (props: IRenameFolderDialogProps) {
    const [folderName, setFolderName] = React.useState<string>();
    const [formError, setFormError] = React.useState<string | undefined>();
    const SaveFolder = useSaveFolder();

    React.useEffect(()=>{
        setFolderName(props.folder.name);
    },[props.folder])

    const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!folderName)
            return;
        async function doWork(){
            var result = await SaveFolder({ 
                parentFolderId: props.folder.parentFolderId, 
                phaseId: props.phaseId, 
                name: folderName, 
                folderId: props.folder.id } as SaveFolderCommand)

            if(result.succeeded)
                props.onClose();
            else{
                setFormError(result.errors?.join('. '))
            }
        }
        doWork();
    }

    const onChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value)
            setFolderName(event.target.value)
    }

    return (
        <Modal open={props.isOpen} onClick={(e)=>e.stopPropagation()} onClose={props.onClose}>
            <ModalDialog>
                <form onSubmit={onFormSubmit}>
                    <Stack spacing={2} direction={'column'} gap={3}>
                        <FormControl>
                            <FormLabel>Enter the new Folder Name</FormLabel>
                            <Input onChange={onChanged} autoFocus required value={folderName}/>
                            {!!formError && <FormHelperText>
                                {formError}
                            </FormHelperText>}
                        </FormControl>
                        <Button type="submit">Rename</Button>
                    </Stack>
                </form>
            </ModalDialog>
        </Modal>
    )
}