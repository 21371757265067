import React from 'react';
import useSecurityApi from '../api/useSecurityApi';
import { useNavigate } from "react-router-dom";
import Card from '@mui/joy/Card';
import Input from '@mui/joy/Input';
import FormLabel from '@mui/joy/FormLabel';
import FormControl from '@mui/joy/FormControl';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import FormHelperText from '@mui/joy/FormHelperText';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/joy/styles';
import { Typography } from '@mui/joy';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginBottom: '10px'
}))

export default function () {
    const [email, setEmail] = React.useState<string>('');    
    const [failFlag, setFailFlag] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const securityApi = useSecurityApi();
    

    

    const onFormSubmit = (e: any) => {
        async function doWork(){
            if(email)
            {
                var result = await securityApi.RequestResetPasswordForEmail(email);
                if(!result.succeeded){
                    setFailFlag(true);
                }
                else{
                    navigate('/')
                }
            }
        }
        doWork();
        e.preventDefault();
        e.stopPropagation();
    }



    return (
        <Card sx={{ width: '300px', height: '300px', alignSelf: 'center' }} variant='outlined'>
            <Typography level='h2'>Reset Password</Typography>
            <form onSubmit={onFormSubmit}>
                <Stack direction={'column'}>
                    <StyledFormControl>
                        <FormLabel>Email</FormLabel>
                        <Input autoFocus required value={email} onChange={e => setEmail(e.target.value)} />
                    </StyledFormControl>                    
                    <Stack>
                        <StyledFormControl error={failFlag}>
                            {failFlag && <FormHelperText >
                                <InfoOutlined />
                                We were unable to reset your password
                            </FormHelperText>}
                            <Button type='submit'>Reset</Button>
                        </StyledFormControl>
                        <StyledFormControl>
                            <Button type='reset' variant='soft'>Cancel</Button>
                        </StyledFormControl>
                    </Stack>
                </Stack>
            </form>
        </Card>
    )
}