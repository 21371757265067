import React, { useState } from 'react';
import { FileDto } from '../../api/useFileSystem';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { useDeleteFile } from '../../api/useFileSystem';
import FormHelperText from '@mui/joy/FormHelperText';

export interface IDeleteFileDialog{
    phaseId: string,    
    file: FileDto,
    isOpen: boolean,
    onClose: ()=>void
}

export default function(props: IDeleteFileDialog){
    const [formError, setFormError] = useState<string>();
    const DeleteFile = useDeleteFile();

    function onDelete(){    
        async function doWork(){
            const localProps = props;
            var result = await DeleteFile({projectId: props.phaseId, fileId: props.file.id!});
            if(result.succeeded)
                props.onClose();
            else{
                setFormError(result.errors?.join('. '));
            }
        }
        doWork();
    }

    return (
        <Modal open={props.isOpen} onClose={props.onClose}>
            <ModalDialog onClick={(e)=>e.stopPropagation()}>
                <Stack direction={'column'} gap={3}>
                    <Typography>Do you want to delete file {props.file.fileName}?</Typography>                    
                    <Button onClick={()=> onDelete()}>Delete</Button>
                    {!!formError && <FormHelperText>
                                {formError}
                            </FormHelperText>}
                </Stack>
            </ModalDialog>
        </Modal>
    )
}