import React from 'react';
import Stack from '@mui/joy/Stack';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { useGetProjects, ProjectDto } from '../../api/useProjects';
import GoogleStaticMap from '../Maps/GoogleStaticMap';
import { Theme, styled } from '@mui/joy/styles';
import { Link } from '@mui/joy';
import { Link as RouterLink } from 'react-router-dom';

const ListItem = styled(Stack)( ({theme}) => ({
    marginTop: '20px',
    width: 'calc(100% - 10px)',
    height: '100px',
    borderWidth: '1px',
    borderRadius: '5px',
    borderStyle:'solid',
    borderColor: theme.vars.palette.primary[300],
}))

export default function ProjectList2(){
    const [projects, setProjects] = React.useState<ProjectDto[]>([]);
    const projectQuery = useGetProjects();

    React.useEffect(()=>{
        if(!projectQuery.isPending && projectQuery.data)
            setProjects(projectQuery.data)
    },[projectQuery.isPending, projectQuery.data])


    return (
        <Box sx={{ overflowY: 'auto', height: '100%', width: '100%'}}>
            { projects.length === 0 && <Typography level={'h3'}>So Empty...</Typography>}
            { projects.map((p,i)=>{
                return (
                    <ListItem key={i} direction={'row'} justifyContent={'start'} alignItems={'center'}>
                        <Stack>
                            <GoogleStaticMap height={100} width={100} latitude={p.centreLatitude} longitude={p.centreLongitude} zoomLevel={p.zoomLevel}/>                        
                        </Stack>
                        <Stack direction='row' alignItems={'start'} sx={{marginLeft: '30px'}}>
                            <Link component={RouterLink} to={'/project/' + p.id} >
                                <Typography color='neutral' level='h4'>{p.referenceNumber} - </Typography>
                                <Typography color='neutral' level='h3'>{p.projectName}</Typography>
                            </Link>
                        </Stack>
                    </ListItem>
                )
            })}
        </Box>
    )
}