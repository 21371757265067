import { Outlet } from "react-router-dom";
import Sheet from '@mui/joy/Sheet';

export default function (){

    const style = {
        height: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center'
    }

    return (
        <Sheet sx={style} variant="soft">
            <Outlet/>
        </Sheet>
    )
}