import React, { ComponentType, ReactElement } from 'react';
import Stack from '@mui/joy/Stack';
import Drawer from '../../Drawer/Drawer';
import ButtonGroup from '@mui/joy/ButtonGroup';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import IconButton from '@mui/joy/IconButton';
import { useGetProject } from '../../../api/useProjects';
import Box from '@mui/joy/Box';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Typography from '@mui/joy/Typography';
import FileTree from '../../Files/FileTree';
import CreateFolderDialog from '../../Files/CreateFolderDialog';
import FileUploadDialog from '../../Files/FileUploadDialog';
import { FileDto, FolderDto } from '../../../api/useFileSystem';
import FileViewer, { IFileClickInfo } from '../../Files/FileViewer';
import styled from '@emotion/styled';
import { IFileMarker } from '../../Files/MarkerItemList';
import { useGetRisks, useAddFilePoint, RiskItemDto } from '../../../api/useRisks';
import RiskFilterSelect from '../../Risks/RiskFilterSelect';
import OpportunityFilterSelect from '../../Sustainability/OpportunityFilterSelect';
import { AddFilePointToRiskCommand, FileMarkerDto, ProjectDto, ProjectPhaseDto } from '../../../api/api.generated.clients';
import EditRiskForm from '../../Risks/EditRiskForm';
import { GetRiskColour } from '../../Risks/RiskHelpers';
import { useGetOpportunityList, useDeleteMapPoint as useDeleteOpportunityMapPoint, SustainableOpportunityDto, AddFilePointToOpportunityCommand, useAddFilePoint as useAddOpportunityFilePoint } from '../../../api/useSustainability';
import EditOpportunityForm from '../../Sustainability/EditOpportunityForm';
import FilterDrawer, { ICanResetFilters } from '../FilterDrawer';
import { Select, Option } from '@mui/joy';
import Split from '@uiw/react-split';
import { IMapMarker } from '../../Maps/GoogleMap';

export interface IToolbarProps {
    project: ProjectDto
    file?: FileDto,
    phaseId: string,
    setFileMarkers?: (markers: IFileMarker[]) => void,
    setMapMarkers?: (markers: IMapMarker[]) => void,
    onPlaceRiskMarker?: (risk: RiskItemDto | undefined) => void,
    onPlaceOpportunityMarker?:( opportunity: SustainableOpportunityDto) => void
}

// export interface IToolbarSettingsProps{
//     file?: FileDto,
// }

export interface IFileViewProps {
    project: ProjectDto,
    phaseId: string,
    Toolbar: ComponentType<IToolbarProps>,

}

interface ICanOpen {
    isOpen: boolean
}

export default function (props: IFileViewProps) {
    const addFileMarker = useAddFilePoint();
    const addOpportunityFileMarker = useAddOpportunityFilePoint();
    const risksQuery = useGetRisks(props.project.id ?? '');
    //  const projectQuery = useGetProject(props.projectId);
    const [risks, setRisks] = React.useState<RiskItemDto[]>([]);
    const [filteredRisks, setFilteredRisks] = React.useState<RiskItemDto[]>([]);
    const [isDrawerOpen, setDrawerOpen] = React.useState<boolean>(true);
    const [isRiskDrawerOpen, setIsRiskDrawerOpen] = React.useState<boolean>(true);
    const [isCreateFolderDialogOpen, setCreateFolderDialogOpen] = React.useState<boolean>(false);
    const [isUploadFileDialogOpen, setUploadFileDialogOpen] = React.useState<boolean>(false);
    const [requestedFolderId, setRequestedFolderId] = React.useState<string>();
    const [currentFolder, setCurrentFolder] = React.useState<FolderDto>();
    // const [selectedFolder, setSelectedFolder] = React.useState<FolderDto>();
    const [selectedFile, setSelectedFile] = React.useState<FileDto>();
    const [addingNote, setAddingNote] = React.useState<boolean>(false);
    const [isAddingMarker, setIsAddingMarker] = React.useState<boolean>(false);
    // const [project, setProject] = React.useState<ProjectDto>();
    const [showCreateRiskForm, setShowCreateRiskForm] = React.useState<boolean>(false);
    const [placeMarkerCallback, setPlaceMarkerCallback] = React.useState<(snapshot: IFileClickInfo) => void>();
    const [markers, setMarkers] = React.useState<IFileMarker[]>([])
    const [opportunityMarkers, setOpportunityMarkers] = React.useState<IFileMarker[]>([])
    const opportunitiesQuery = useGetOpportunityList(props.project.id ?? '');
    const [opportunities, setOpportunities] = React.useState<SustainableOpportunityDto[]>([])
    const [filteredOpportunities, setFilteredOpportunities] = React.useState<SustainableOpportunityDto[]>([]);
    const [showCreateOpportunityForm, setShowCreateOpportunityForm] = React.useState<boolean>(false);
    const [selectedOpportunity, setSelectedOpportunity] = React.useState<SustainableOpportunityDto | undefined>();
    const [showFilterDrawer, setShowFilterDrawer] = React.useState<boolean>(false);
    const filterRef = React.useRef<ICanResetFilters>(null);
    const [hasActiveFilters, setHasActiveFilters] = React.useState<boolean>(false);
    const [folderStack, setFolderStack] = React.useState<FolderDto[]>([])
    // const [selectedPhaseId, setSelectedPhaseId] = React.useState<string>();

    // React.useEffect(() => {
    //     setSelectedPhaseId(props.project?.phases?.[0]?.id)
    // }, [props.project]);

    React.useEffect(() => {
        if (!!selectedFile) {
            loadMarkersForRisks();
        }
    }, [filteredRisks, selectedFile]);    


    // React.useEffect(() => {
    //     if (!!selectedFile)
    //         loadMarkersForOpportunities();
    // }, [filteredOpportunities, selectedFile])

    

    function onPlaceRiskMarkerClick(risk: RiskItemDto | undefined) {
        setIsAddingMarker(!!risk);
        if (!!risk) {

            const callback = (snapshot: IFileClickInfo) => {
                addFileMarker({
                    riskId: risk.id,
                    fileId: snapshot.fileId,
                    pageNumber: snapshot.page,
                    xPos: Math.round(snapshot.x),
                    yPos: Math.round(snapshot.y)
                } as AddFilePointToRiskCommand);
                setIsAddingMarker(false);
            }

            setPlaceMarkerCallback((_prev: any) => callback);
        }
    }

    function onPlaceOpportunityMarkerClick(opportunity: SustainableOpportunityDto | undefined) {
        setIsAddingMarker(!!opportunity);
        if (!!opportunity) {

            const callback = (snapshot: IFileClickInfo) => {
                addOpportunityFileMarker({
                    opportunityId: opportunity.id,
                    fileId: snapshot.fileId,
                    pageNumber: snapshot.page,
                    xPos: Math.round(snapshot.x),
                    yPos: Math.round(snapshot.y)
                } as AddFilePointToOpportunityCommand);
                setIsAddingMarker(false);
            }

            setPlaceMarkerCallback((_prev: any) => callback);
        }
    }

    function onFileClicked(e: IFileClickInfo) {
        if (!isAddingMarker)
            return;

        setIsAddingMarker(false);
        placeMarkerCallback?.(e);
    }

    function onFolderClicked(folder : FolderDto | undefined){

        if(!folder)
            setFolderStack([])
        else if(!folder.parentFolderId)
            setFolderStack([folder])
        else if(folderStack.some(x=>x.id === folder.parentFolderId)){
            const newStack = folderStack.reduce((prev, curr) => 
                curr.id !== folder?.parentFolderId ? [...prev, curr] : prev , 
            [] as FolderDto[])
        }
    }

    function loadMarkersForRisks() {
        // const risksAndMarkers = filteredRisks
        //     .map(r => r.fileMarkers?.filter(f => f.fileId === selectedFile?.id).map(m => ({ risk: r, markers: m })) ?? [])
        //     .reduce((prev, curr) => [...prev, ...curr], []);

        // const fileMarkers = risksAndMarkers.map(x => {
        //     return ({
        //         fileId: x.markers.fileId,
        //         id: x.markers.id,
        //         pageNumber: x.markers.pageNumber,
        //         xPos: x.markers.xPos,
        //         yPos: x.markers.yPos,
        //         itemId: x.risk.id,
        //         itemTitle: x.risk.referenceNumber,
        //         colour: GetRiskColour(x.risk),
        //         isRisk: true
        //     } as IFileMarker)

        // })

        // setMarkers(fileMarkers);
    }

    function loadMarkersForOpportunities() {
        const OpportunitiesAndMarkers = filteredOpportunities
            .map(r => r.fileMarkers?.filter(f => f.fileId === selectedFile?.id).map(m => ({ risk: r, markers: m })) ?? [])
            .reduce((prev, curr) => [...prev, ...curr], []);

        const fileMarkers = OpportunitiesAndMarkers.map(x => {
            return ({
                fileId: x.markers.fileId,
                id: x.markers.id,
                pageNumber: x.markers.pageNumber,
                xPos: x.markers.xPos,
                yPos: x.markers.yPos,
                itemId: x.risk.id,
                itemTitle: x.risk.referenceNumber,
                colour: 'blue',
                isRisk: false
            } as IFileMarker)

        })

        setOpportunityMarkers(fileMarkers);
    }

    function FolderLabel(){

        return <Stack direction={'row'} gap={1} justifyContent={'flex-start'}>
            <Typography sx={{fontWeight:'bold'}}>Phase:</Typography>
            <Typography>{props.project.phases?.find(x=>x.id === props.phaseId)?.phaseName}</Typography>
            <Typography>{'>'}</Typography>
            <Typography sx={{fontWeight:'bold'}}>Folder: </Typography>
            <Typography>{currentFolder?.name ?? 'Root'}</Typography>
            <Typography>{'>'}</Typography>
            <Typography sx={{fontWeight:'bold'}}>File:</Typography>
            <Typography>{selectedFile?.fileName}</Typography>
        </Stack>
    }

    

    if (!props.project || !props.project.id)
        return <>no project</>

    return (
        <Box sx={{ height: '100%' }}>
            {/* <Stack direction={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} sx={{ height: '100%', overflow: 'hidden' }}> */}

            {props.project && <props.Toolbar 
                                phaseId={props.phaseId} 
                                project={props.project} 
                                file={selectedFile} 
                                setFileMarkers={(mx) => setMarkers(mx)} 
                                onPlaceRiskMarker={(risk)=> onPlaceRiskMarkerClick(risk)} 
                                onPlaceOpportunityMarker={(op)=> onPlaceOpportunityMarkerClick(op)} />}

            <Box sx={{ height: 'calc(100% - 20px)', width: '100%' }}>


                <Split style={{ height: '100%' }}>
                    <Box sx={{ width: '300px', height: '100%' }}>
                        <Stack direction={'row'} justifyContent={'flex-start'}>
                            <IconButton onClick={() => setCreateFolderDialogOpen(true)}>
                                <CreateNewFolderIcon />
                            </IconButton>
                            <IconButton onClick={() => setUploadFileDialogOpen(true)}>
                                <UploadFileIcon />
                            </IconButton>
                        </Stack>
                        <Box sx={{  height: 'calc(100% - 34px)', position: 'relative', overflow: 'auto' }}>
                            <Box sx={{ position: 'absolute', overflow: 'hidden' }}>
                                <FileTree
                                    phaseId={props.phaseId ?? ''}
                                    
                                    onFileClicked={(f) => setSelectedFile(f)}
                                    onFolderClicked={(f) => setCurrentFolder(f)}
                                    // onFolderDoubleClicked={(f) => setCurrentFolder(f)}
                                    // hasParentFolder={!!currentFolder}
                                    // onRequestFolderLoad={(folderId) => setRequestedFolderId(folderId)} 
                                    />
                            </Box>
                        </Box>
                    </Box>
                    <Stack direction={'row'} justifyContent={'center'} sx={{ width: '100%', position: 'relative' }}>
                        <Box sx={{ maxHeight: '100%', width: '100%'  }}>
                            {selectedFile ? <FileViewer
                                fileLabel={<FolderLabel/>}
                                addNote={addingNote}
                                onNoteAdded={() => setAddingNote(false)}
                                phaseId={props.phaseId ?? ''}
                                file={selectedFile}
                                fileMarkers={[...markers, ...opportunityMarkers]}
                                onFileClicked={(e) => onFileClicked(e)} /> :
                                <Box pt={'20px'}><Typography>Select a file to display</Typography></Box>}
                        </Box>
                    </Stack>
                </Split>

                <CreateFolderDialog
                            isOpen={isCreateFolderDialogOpen}
                            onClose={() => setCreateFolderDialogOpen(false)}
                            phaseId={props.phaseId ?? ''}
                            parentfolderId={currentFolder?.id} />
                        <FileUploadDialog
                            isOpen={isUploadFileDialogOpen}
                            onClose={() => setUploadFileDialogOpen(false)}
                            projectId={props.project.id ?? ''}
                            phaseId={props.phaseId ?? ''}
                            folderId={currentFolder?.id}
                        />
                
            </Box>
            {/* <EditRiskForm isNew={true} isOpen={showCreateRiskForm} projectId={props.project.id ?? ''} onClose={() => setShowCreateRiskForm(false)} /> */}
            {/* <EditOpportunityForm isNew={true} isOpen={showCreateOpportunityForm} onClose={() => setShowCreateOpportunityForm(false)} phaseId={selectedPhaseId ?? ''} projectId={props.project.id ?? ''} /> */}
            {/* <FilterDrawer 
                isOpen={showFilterDrawer} 
                onClose={() => setShowFilterDrawer(false)} 
                onRisksFiltered={r=> setRisks(r)}
                onOpportunitiesFiltered={o=>setOpportunities(o)}
                projectId={props.project.id ?? ''} 
                onActiveFiltersChanged={setHasActiveFilters}
                ref={filterRef}/> */}
        </Box>
    )
}


