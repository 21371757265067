import React from 'react';
import Typography from '@mui/joy/Typography';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FileUploadDialog from './FileUploadDialog';
import CreateFolderDialog from './CreateFolderDialog';
import { FileDto } from '../../api/useFileSystem';
import MenuButton from '@mui/joy/MenuButton';
import { Dropdown } from '@mui/joy';
import DeleteFileDialog from './DeleteFileDialog';
import RenameFileDialog from './RenameFileDialog';

export interface IFileContextMenuProps {
    phaseId: string,
    folderId: string | undefined,
    file: FileDto
}

export default function (props: IFileContextMenuProps) {
    const [isDeleteFileDialogOpen, setDeleteFileDialogOpen] = React.useState<boolean>(false);
    const [isRenameFileDialogOpen, setRenameFileDialogOpen] = React.useState<boolean>(false);


    return <>
        <Dropdown>
            <MenuButton variant='plain' size='sm' onClick={(e) => e.stopPropagation()}>
                <MoreHorizIcon />
            </MenuButton>
            <Menu>
                <MenuItem onClick={(e) => { setDeleteFileDialogOpen(true); e.stopPropagation() }}><Typography>Delete File</Typography></MenuItem>
                <MenuItem onClick={(e) => { setRenameFileDialogOpen(true); e.stopPropagation() }}><Typography>Rename File</Typography></MenuItem>
            </Menu>
        </Dropdown>
        <DeleteFileDialog file={props.file} isOpen={isDeleteFileDialogOpen} onClose={() => setDeleteFileDialogOpen(false)} phaseId={props.phaseId} />
        <RenameFileDialog file={props.file} isOpen={isRenameFileDialogOpen} phaseId={props.phaseId} onClose={() => setRenameFileDialogOpen(false)} parentfolderId={props.folderId} />
    </>
}