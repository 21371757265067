import { Box, Stack } from '@mui/joy';
import React from 'react';
import GoogleMap, { IGoogleMapRef, IGoogleMapSnapshot } from '../Maps/GoogleMap';
const mapRef = React.createRef<IGoogleMapRef>()

export interface IProjectMapSelectorProps{
    latitude?: number,
    longitude?: number,
    zoomLevel?: number,
    onUpdated: (snapshot: IGoogleMapSnapshot) => void
}



 function ProjectMapSelector(props : IProjectMapSelectorProps){
    console.log(`fixin selector: ${JSON.stringify(props)}`)
    function onValueUpdated(snapshot: IGoogleMapSnapshot){
        console.log(`value updated: ${JSON.stringify(snapshot)}`)
        props.onUpdated(snapshot)
    }

    return <Stack spacing={2} alignItems={'center'}>
        <Box sx={{ width: '600px', height: '600px' }}>
            <GoogleMap 
            ref={mapRef} 
            latitude={props.latitude ?? -26.47} 
            longitude={ props.longitude ?? -228.207} 
            zoomLevel={ props.zoomLevel ?? 4} 
            onMapViewChanged={(e)=> onValueUpdated(e)} />
        </Box>
    </Stack>
}

export default React.memo(ProjectMapSelector)