import React from 'react';
import Tabs from '@mui/joy/Tabs';
import Tab from '@mui/joy/Tab';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import { Stack, Card } from '@mui/joy';
import UserAdmin from '../components/Users/UserAdmin';
import RefNumGenSettings from '../components/Common/RefNumGenSettings';

export default function () {

    return (
        <Stack direction={'column'} sx={{ height: '100%', width: '100%'}}>
            <Card >
                <Tabs>
                    <TabList>
                        <Tab>Users</Tab>
                        <Tab>Reference Number Auto-Generation</Tab>
                    </TabList>
                    <TabPanel value={0}>
                        <UserAdmin />
                    </TabPanel>
                    <TabPanel value={1}>
                        <RefNumGenSettings  />
                    </TabPanel>
                </Tabs>
            </Card>
        </Stack>
    )
}