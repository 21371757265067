import React from 'react';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import ButtonGroup from '@mui/joy/ButtonGroup';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import { FileDto, useGetAnnotations } from '../../api/useFileSystem';
import { FileType, SaveAnnotationCommand } from '../../api/api.generated.clients';
// import { Document, Page } from 'react-pdf';
// import { pdfjs } from 'react-pdf';
// import 'react-pdf/dist/Page/AnnotationLayer.css';
// import 'react-pdf/dist/Page/TextLayer.css';
import './style.css';
import ReactDOM from 'react-dom';
import { Icon } from '@mui/material';
// import { OnRenderSuccess, PageCallback } from 'react-pdf/dist/cjs/shared/types';
import Board, { IAnnotationsProps } from '../Annotations/Board';
import { INoteProps, INoteUpdatedEvent } from '../Annotations/Note';
import { IFileViewerProps } from './FileViewer';
import { IFileClickInfo } from './FileViewer';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PdfPageViewer from './PDF/PdfPageViewer';
import { Typography } from '@mui/joy';
// import "pdfjs-dist/build/pdf.worker.entry";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();

interface INoteAndPage {
  note: INoteProps,
  page?: number | undefined
}


export default function (props: IFileViewerProps) {
  const file = React.useMemo(() => ({ url: props.file?.url || '' }), [props.file]);
  const documentOptions = React.useMemo(() => ({ httpHeaders: { "Content-Type": "application/pdf" } }), [])
  const [isRendered, setIsRendered] = React.useState<boolean>();
  const [scale, setScale] = React.useState<number>(0.99);
  const [height, setHeight] = React.useState<number>(500);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [numPages, setNumPages] = React.useState<number>(1);
  const canvas = React.useRef<HTMLCanvasElement>(null);
  const [notes, setNotes] = React.useState<INoteAndPage[]>([]);
  const annotationsQuery = useGetAnnotations(props.file.id || '');
  const [notesForPage, setNotesForPage] = React.useState<INoteProps[]>([]);

  React.useEffect(() => {
    setIsRendered(false);
    setScale(0.99);
    setCurrentPage(1);

  }, [props.file]);

  React.useEffect(() => {
    setNotesForPage(notes.filter(x => !x.page || x.page === currentPage).map(x => x.note));
  }, [currentPage, notes])

  React.useEffect(() => {
    if (annotationsQuery.data && !annotationsQuery.isPending && !annotationsQuery.error && annotationsQuery.data.succeeded && annotationsQuery.data.data) {
      const notes = annotationsQuery.data.data?.map(x => {
        const notesAndPage = {
          page: x.pageNumber,
          note: {
            id: x.id,
            colourHex: x.colourHex,
            x: x.xPos,
            y: x.yPos,
            authorId: x.authorId,
            height: x.height,
            text: x.text,
            width: x.width
          } as INoteProps
        } as INoteAndPage;
        return notesAndPage;
      });
      setNotes(notes);
    }
  }, [annotationsQuery.data, annotationsQuery.isPending])



  function onLoadSuccess(args: any) {
    setIsRendered(true);
    setHeight(args.originalHeight);
  }

  function onZoomOut() {
    const newScale = scale * 0.9;
    const clamped = Math.max(0.5, newScale);
    setScale(clamped);
  }

  function onZoomIn() {
    const newScale = scale * 1.1;
    const clamped = Math.min(2, newScale);
    setScale(clamped);
  }

  function prevPage() {
    const newPage = currentPage - 1;
    const clamped = Math.max(1, newPage);
    setCurrentPage(clamped);
  }

  function nextPage() {
    const newPage = currentPage + 1;
    const clamped = Math.min(newPage, numPages);
    setCurrentPage(clamped);
  }

  // function onAddAnnotation(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
  //   if (!props.addNote)
  //     return;

  //   var bounds = e.currentTarget.getBoundingClientRect();
  //   var note = {
  //     note: {
  //       colourHex: '#ebd234',
  //       x: (e.clientX - bounds.left) / scale,
  //       y: (e.clientY - bounds.top) / scale,
  //       text: 'enter text...',
  //       width: 200,
  //       height: 200
  //     } as INoteProps,
  //     page: currentPage
  //   } as INoteAndPage;
  //   setNotes([...notes, note])
  //   props.onNoteAdded();
  // }

  // function onAnnotationSaved(e: INoteUpdatedEvent) {
  //   if (e?.note) {
  //     const doWork = async () => {
  //       if (!e.deleted) {
  //         SaveAnnotation({
  //           id: e.note.id,
  //           colourHex: e.note.colourHex,
  //           fileId: props.file.id,
  //           pageNumber: currentPage,
  //           projectId: props.projectId,
  //           text: e.note.text,
  //           xPos: Math.round(e.note.x),
  //           yPos: Math.round(e.note.y),
  //           height: e.note.height,
  //           width: e.note.width
  //         } as SaveAnnotationCommand);
  //       }
  //       else {
  //         if (e.note.id && props.file.id)
  //           DeleteAnnotation(e.note.id, props.file.id);
  //       }
  //     }
  //     doWork();
  //   }
  // }

  function onPdfClicked(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    var bounds = e.currentTarget.getBoundingClientRect();
    const clickInfo = {
      x: (e.clientX - bounds.left) / scale,
      y: (e.clientY - bounds.top) / scale,
      page: currentPage,
      fileId: props.file.id
    } as IFileClickInfo;

    props.onFileClicked(clickInfo);
  }

  if (!props.file || !canvas)
    return <Box>Waiting for Pdf data</Box>

    console.log(`currentPage: ${currentPage}`)

  return (
    <Box sx={{ height: '100%' }}>
      <Stack direction='row' height='40px' alignItems={'center'} gap={2}>
        <ButtonGroup >
          <IconButton variant='soft' color='primary' onClick={onZoomOut}><ZoomOutIcon /></IconButton>
          <IconButton variant='soft' color='primary' onClick={onZoomIn}><ZoomInIcon /></IconButton>
          <IconButton variant='soft' color='primary' onClick={prevPage}><UndoIcon /></IconButton>
          {isRendered && <Button disabled>{currentPage} of {numPages}</Button>}
          <IconButton variant='soft' color='primary' onClick={nextPage}><RedoIcon /></IconButton>
        </ButtonGroup>
        <Typography level={'title-lg'}>{props.fileLabel}</Typography>
      </Stack>
      <Box sx={{ height: 'calc(100% - 40px)', position: 'relative', overflow: 'auto', width: '100%', pl: '20px' }} >
        
          <Board notes={[]} markers={props.fileMarkers.filter(f => f.pageNumber === currentPage)} zoomScale={scale} onNoteSaved={() => { }} onBoardClicked={onPdfClicked} >
            <PdfPageViewer file={file} pageNum={currentPage} scale={scale} onLoaded={e => setNumPages(e.numPages)} />
          </Board>
        
      </Box>
    </Box>
  )
}

