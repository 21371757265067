import { SaveIdTemplateCommand, IdGenerationItemDto, IdGenerationType, ResultOfIdGenerationItemDto, IdTemplateClient } from './api.generated.clients'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

const client = new IdTemplateClient();

// const useGetFolder = (projectId: string, folderId: string | undefined) => {
//     return useQuery({
//         queryKey: ['folder', projectId, folderId],
//         queryFn: () => client.folderGet(projectId, folderId)
//     });
// }

const useGetIdTemplate = (templateType: IdGenerationType) => {
    return useQuery({
        queryKey: ['idtemplate', templateType],
        queryFn: ()=> client.idTemplateGet(templateType),
        enabled: templateType != null && templateType !== undefined
    })
}

const useSaveIdTemplate = () => {
    const queryClient = useQueryClient();

    const saveIdTemplateMutation = useMutation({
        mutationFn: (cmd: SaveIdTemplateCommand) => client.idTemplatePost(cmd),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({
                queryKey: ['idtemplate', variables.templateType]
            })
        }
    });

    return saveIdTemplateMutation.mutateAsync;
}


export { useGetIdTemplate, useSaveIdTemplate, IdGenerationType }
export type { SaveIdTemplateCommand, IdGenerationItemDto, ResultOfIdGenerationItemDto}