import React from 'react';
import { FileDto } from '../../../api/useFileSystem';
import "pdfjs-dist/build/pdf.worker.mjs";
import Box from '@mui/joy/Box';
require('pdfjs-dist')

export interface IPdfPageViewerProps {
  // phaseId: string,
  file: FileDto,
  pageNum: number,
  scale: number,
  onLoaded: (args : IDocumentLoaded) => void
}

export interface IDocumentLoaded{
  numPages: number
}

export default function (props: IPdfPageViewerProps) {
  const file = React.useMemo(() => ({ url: props.file?.url || '' }), [props.file]);
  const documentOptions = React.useMemo(() => ({ httpHeaders: { "Content-Type": "application/pdf" } }), [])
  const [scale, setScale] = React.useState<number>(0.99);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [pdfDoc, setPdfDoc] = React.useState<any>(null);
  const canvasRef = React.useRef<any | null>(null);
  
  var { pdfjsLib }: any = globalThis;
  pdfjsLib.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
  ).toString();

  var ctx: any | null = canvasRef.current?.getContext('2d');

  React.useEffect(() => {
    setScale(0.99);
    setCurrentPage(1);
    getDocument();
  }, [props.file]);

  React.useEffect(()=>{
    setScale(props.scale);
    setCurrentPage(props.pageNum);    
  },[props.scale, props.pageNum]);  

  React.useEffect(()=>{
    if(pdfDoc)
      renderPage(currentPage);
  },[pdfDoc, scale, currentPage])

  function getDocument() {

    var args = {...documentOptions, url: file.url}

    pdfjsLib.getDocument(args).promise.then(function (pdfDoc_: any) {
      setPdfDoc(pdfDoc_);
      props.onLoaded({ numPages: pdfDoc_.numPages} as IDocumentLoaded)
    });
  }

  function renderPage(num: number) {    
    pdfDoc!.getPage(num).then(function (page: any) {
      var viewport = page.getViewport({ scale: scale });
      canvasRef.current!.height = viewport.height;
      canvasRef.current!.width = viewport.width;

      var renderContext = {
        canvasContext: ctx,
        viewport: viewport
      };
      var renderTask = page.render(renderContext);
    });

  }

  if (!props.file || !canvasRef)
    return <Box>Waiting for Pdf data</Box>

  return (
    <Box sx={{ position: 'absolute',  top:'0', left: '0'}}>
      <canvas ref={canvasRef} />
    </Box>
  )
}

