import React from 'react';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Input from '@mui/joy/Input';
import { useUploadFile, FileParameter } from '../../api/useFileSystem';


export interface IFileUploadDialogProps {
    isOpen: boolean,
    projectId: string,
    phaseId: string,
    folderId?: string | undefined,
    onClose: () => void
}

export default function (props: IFileUploadDialogProps) {
    const [file, setFile] = React.useState<File | undefined>();
    const [formError, setFormError] = React.useState<string | undefined>();
    const UploadFile = useUploadFile();

    const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!file)
            return;

        async function doWork(){
            const formData = new FormData();
            formData.append('file', file!);
            formData.append('fileName', file!.name);
            formData.append('fileType', file!.type)         
            var result = await UploadFile({ projectId:props.projectId, phaseId: props.phaseId, formFile: { data : file, fileName: file!.name} as FileParameter, folderId:props.folderId, fileType: file!.type});

            if(result.succeeded)
                props.onClose();
            else{
                setFormError(result.errors?.join('. '))
            }
        }
        doWork()
    }

    const onFileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files)
            setFile(event.target.files[0])
    }

    return (
        <Modal open={props.isOpen} onClose={props.onClose}>
            <ModalDialog>
                <form onSubmit={onFormSubmit}>
                    <Stack spacing={2} direction={'column'} gap={3}>
                        <FormControl>
                            <FormLabel>Select a file</FormLabel>
                            <Input type='file' onChange={onFileChanged} autoFocus required />
                            {!!formError && <FormHelperText>
                                {formError}
                                </FormHelperText>}
                        </FormControl>
                        <Button type="submit">Submit</Button>
                    </Stack>
                </form>
            </ModalDialog>
        </Modal>
    )
}