import React from 'react';
import { IToolbarProps } from './FlexiFileView';
import Stack from '@mui/joy/Stack';
import { Select, Option, ButtonGroup, Button, Typography } from '@mui/joy';
import RiskFilterSelectToolbar from '../../Risks/RiskFilterSelectToolbar';
import { FileDto } from '../../../api/useFileSystem';
import { AddFilePointToRiskCommand, MapMarkerDto, RiskItemDto } from '../../../api/api.generated.clients';
import EditRiskForm from '../../Risks/EditRiskForm';
import { useAddFilePoint, useGetRisks } from '../../../api/useRisks';
import { IFileClickInfo } from '../../Files/FileViewer';
import { IFileMarker } from '../../Files/MarkerItemList';
import { GetRiskColour } from '../../Risks/RiskHelpers';
import { riskIcon, IMapMarker } from '../../Maps/GoogleMap';
import SelectedRiskMarkerOverlay from '../../Risks/SelectedRiskMarkerOverlay';
import { useDeleteMapPoint } from '../../../api/useRisks';
import AddMapMarkerToRiskDialog from '../../Maps/AddMapMarkerToRiskDialog';

interface ICanResetFilters {
    resetFilters: () => void
}

export default function (props: IToolbarProps) {
    const addFileMarker = useAddFilePoint();
    const [hasActiveFilters, setHasActiveFilters] = React.useState<boolean>(false);
    // const [selectedPhaseId, setSelectedPhaseId] = React.useState<string>();
    const [showCreateRiskForm, setShowCreateRiskForm] = React.useState<boolean>(false);
    const [showFilterDrawer, setShowFilterDrawer] = React.useState<boolean>(false);
    const filterRef = React.useRef<ICanResetFilters>(null);
    const [risks, setRisks] = React.useState<RiskItemDto[]>([]);
    const [filteredRisks, setFilteredRisks] = React.useState<RiskItemDto[]>([]);
    const risksQuery = useGetRisks(props.project.id ?? '');
    const [isAddingMarker, setIsAddingMarker] = React.useState<boolean>(false);
    const [placeMarkerCallback, setPlaceMarkerCallback] = React.useState<(snapshot: IFileClickInfo) => void>();
    const deleteMapMarker = useDeleteMapPoint();

    // React.useEffect(() => {
    //     if (props.project && props.project.phases && props.project.phases.length > 0) {
    //         setSelectedPhaseId(props.project.phases[0].id)
    //     }
    // }, [props.project]);

    React.useEffect(() => {
        if (!risksQuery.isError && !risksQuery.isPending && risksQuery.data) {
            setRisks(risksQuery.data.filter(r => r.phaseId === props.phaseId));
            setFilteredRisks(risksQuery.data.filter(r => r.phaseId === props.phaseId))
        }
    }, [risksQuery.isPending, risksQuery.data, props.phaseId])

    React.useEffect(()=>{
        loadFileMarkersForRisks()
        loadMapMarkersForRisks()
    },[filteredRisks])

    React.useEffect(()=>{
        loadFileMarkersForRisks()
    },[props.file])

    function loadMapMarkersForRisks() {
        const risksAndMarkers = filteredRisks
            .map(r => r.mapMarkers?.map(m => ({ risk: r, markers: m })) ?? [])
            .reduce((prev, curr) => [...prev, ...curr], []);

        const mapMarkers = risksAndMarkers.map(m => ({
            latitude: m.markers.centreLatitude,
            longitude: m.markers.centreLongitude,
            id: m.markers.id,
            colour: GetRiskColour(m.risk),
            overlayComponent: () => <SelectedRiskMarkerOverlay
                title={m.risk.referenceNumber}
                onDeleteMarker={() => doDeleteRiskMapMarker(m.markers, m.risk)} />,
            showOverlay: false,
            icon: riskIcon
        } as IMapMarker))

        props.setMapMarkers?.(mapMarkers)
    }

    function doDeleteRiskMapMarker(marker: MapMarkerDto, risk: RiskItemDto) {
        async function doWork() {
            const result = await deleteMapMarker({ riskId: risk.id!, markerId: marker.id! })
            if (result.succeeded) {
                loadMapMarkersForRisks();
            }
        }
        doWork();
    }

    function loadFileMarkersForRisks() {
        const risksAndMarkers = filteredRisks
            .map(r => r.fileMarkers?.filter(f => f.fileId === props.file?.id).map(m => ({ risk: r, markers: m })) ?? [])
            .reduce((prev, curr) => [...prev, ...curr], []);

        const fileMarkers = risksAndMarkers.map(x => {
            return ({
                fileId: x.markers.fileId,
                id: x.markers.id,
                pageNumber: x.markers.pageNumber,
                xPos: x.markers.xPos,
                yPos: x.markers.yPos,
                itemId: x.risk.id,
                itemTitle: x.risk.referenceNumber,
                colour: GetRiskColour(x.risk),
                isRisk: true
            } as IFileMarker)

        })
        props.setFileMarkers?.(fileMarkers);
    }

    return (<Stack direction='row' sx={{ width: '100%' }} alignItems={'center'}>
        
        <ButtonGroup>
            <Button variant='solid' color='primary' onClick={() => setShowCreateRiskForm(true)}>Add Risk</Button>
        </ButtonGroup>
        <RiskFilterSelectToolbar
            disabled={false}
            project={props.project!}
            risks={risks}
            onRisksSet={(risks) => setFilteredRisks(risks)}
            onAddMarker={(risk) => props.onPlaceRiskMarker?.(risk)} 
            />


        {/* <ButtonGroup disabled={!props.file} sx={{ ml: '10px', mr: '10px' }}>
                        <Button onClick={() => setShowFilterDrawer(true)}>Manage Filters</Button>
                        <Button onClick={() => filterRef.current?.resetFilters()}>Clear Filters</Button>
                    </ButtonGroup>
                    {hasActiveFilters && <Typography color='warning'>There are Active Filters</Typography>}  */}
        <EditRiskForm isNew={true}
            isOpen={showCreateRiskForm}
            projectId={props.project.id ?? ''}
            onClose={() => setShowCreateRiskForm(false)} />
        
    </Stack>)



}