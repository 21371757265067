import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { CollaboratorClient, SaveCollaboratorCommand, CollaboratorDto } from './api.generated.clients';

interface IQueryResponse<T> {
    isPending: boolean,
    data: T | undefined,
    error: Error
}


const collaboratorClient = new CollaboratorClient();    

const useSaveCollaborator =  () =>{
    const queryClient = useQueryClient();
    const saveCollaboratorMutation = useMutation({
        mutationFn: async (cmd: SaveCollaboratorCommand ) => {
            var response = await collaboratorClient.collaboratorPost(cmd)
            return response;
        },
        onSuccess: (data, variables)=>{
            queryClient.invalidateQueries({queryKey: ['project', variables.projectId]});
            queryClient.invalidateQueries({queryKey: ['collaborators', variables.id]})
        }
    });
    
    return saveCollaboratorMutation.mutateAsync;        
}

const useDeleteCollaborator =  () =>{
    const queryClient = useQueryClient();
    const deleteCollaboratorMutation = useMutation({
        mutationFn: async ({projectId, collaboratorId}:{projectId: string, collaboratorId: string}) => {
            var response = await collaboratorClient.collaboratorDelete(projectId, collaboratorId)
            return response;
        },
        onSuccess: (data, variables)=>{
            queryClient.invalidateQueries({queryKey: ['project', variables.projectId]});
            queryClient.invalidateQueries({queryKey: ['collaborators']})
        }
    });
    
    return deleteCollaboratorMutation.mutateAsync;        
}


export type { SaveCollaboratorCommand, CollaboratorDto };
export { useSaveCollaborator, useDeleteCollaborator }