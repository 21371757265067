import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import React from 'react';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import FileContextMenus from './FileContextMenus';
import FolderContextMenus from './FolderContextMenus';
import UndoIcon from '@mui/icons-material/Undo';
import FolderIcon from '@mui/icons-material/Folder';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useGetFolder, FileDto, FolderDto, ResultOfFolderDto } from '../../api/useFileSystem';
import { FilePresent } from '@mui/icons-material';
import { Box, styled } from '@mui/joy';
import { FileType } from '../../api/api.generated.clients';

export interface IFileTreeProps {
    phaseId: string,
    // folderId: string | undefined,
    // hasParentFolder?: boolean,
    onFolderClicked?: (folder: FolderDto | undefined) => void,
    onFileClicked?: (file: FileDto | undefined) => void,
    // onFileDoubleClicked?: (file: FileDto) => void,
    // onFolderDoubleClicked?: (folder: FolderDto) => void,
    // onRequestFolderLoad?: (folderId: string | undefined) => void
}



export default function (props: IFileTreeProps) {   

    const [currentParentFolderId, setCurrentParentFolderId] = React.useState<string>();
    const [currentFolderId, setCurrentFolderId] = React.useState<string>();
    // const [rootFolder, setRootFolder] = React.useState<FolderDto>();
    const [folder, setFolder] = React.useState<FolderDto>();
    const [selectedFile, setSelectedFile] = React.useState<FileDto>();    
    const [parentFolder, setParentFolder] = React.useState<FolderDto>()
    const [hoverItem, setHoverItem] = React.useState<FileDto | FolderDto | undefined>();
    const folderQuery = useGetFolder(props.phaseId, currentFolderId);    
    const parentFolderQuery = useGetFolder(props.phaseId, currentParentFolderId)

    React.useEffect(() => {        
        if (!folderQuery.error && folderQuery.data && folderQuery.data.succeeded) {
            // if(!rootFolder && !currentFolderId){
            //     setRootFolder(folderQuery.data.data)
            // }
            console.log('updating folder: ' + folderQuery.data.data?.id + ', phaseId: ' + props.phaseId)
            setFolder(folderQuery.data.data);
            setCurrentParentFolderId(folderQuery.data.data?.parentFolderId);
            setParentFolder(undefined);
        }
    }, [folderQuery.data, folderQuery.isPending])

    
    React.useEffect(()=>{
        onFileClicked(undefined)
        setCurrentFolderId(undefined)
        props.onFolderClicked?.(undefined)
    },[props.phaseId])

    React.useEffect(()=>{
        if(!!currentParentFolderId && parentFolderQuery.data?.succeeded){
            setParentFolder(parentFolderQuery.data.data)
        }
    },[parentFolderQuery.isPending, parentFolderQuery.data])
    
    if (!folder) {
        return (
            <Stack direction={'column'} sx={{ height: '100%' }}>
                <Typography>Retrieving Folder Data...</Typography>
            </Stack>
        )
    }

    function onFileClicked(f: FileDto | undefined){
        setSelectedFile(f);
        props?.onFileClicked?.(f)
    }

    function onFolderClicked(folder: FolderDto | undefined){
        onFileClicked(undefined)
        // if(folder){
            setCurrentFolderId(folder?.id)
            props.onFolderClicked?.(folder)
        // }
        // else{
        //     setFolder(rootFolder);
        //     props.onFolderClicked?.(rootFolder)
        // }
        
    }

    
    return (
        <Box sx={{ maxWidth: '400px' }}>
            <Stack direction={'row'} justifyContent={'flex-start'} sx={{ width: '100%'}}>
                <Typography level={'h3'} sx={{textDecoration: 'underline'}} display="inline">{folder.name}</Typography>
            </Stack>
            <List size='sm' sx={{ display: 'block'}}>
                {!!folder?.id  && <ListItem>
                    <ListItemButton onClick={() => onFolderClicked(parentFolder)}>
                        <ListItemDecorator><UndoIcon color='error' /></ListItemDecorator>
                        <ListItemContent>Go Back</ListItemContent>
                    </ListItemButton>
                </ListItem>}
                {folder?.subFolders?.map(fo => {
                    return (
                        <ListItem>
                            <ListItemButton onMouseEnter={()=> setHoverItem(fo)} onClick={() => onFolderClicked(fo)}>
                                <ListItemDecorator><FolderIcon color='warning'/></ListItemDecorator>
                                <ListItemContent sx={{ userSelect: 'none',  maxWidth: '300px' }}>
                                    <Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
                                        <Typography >{fo.name}</Typography>
                                        { fo === hoverItem && <FolderContextMenus phaseId={props.phaseId} folder={fo} /> }
                                    </Stack>
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                    )
                })}
                {folder?.files?.map(fi => {
                    return (
                        <ListItem onMouseEnter={()=>setHoverItem(fi)}>   
                            <ListItemButton selected={fi === selectedFile} onClick={() => onFileClicked(fi)} >
                                <ListItemDecorator>
                                    {fi.fileType === FileType.PdfType ? <PictureAsPdfIcon color='info'/> : <InsertDriveFileIcon color='info'/>}
                                </ListItemDecorator>
                                <ListItemContent sx={{ userSelect: 'none', pr:'5px', maxWidth: '300px' }}>
                                <Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
                                        <Typography>{fi.fileName}</Typography>
                                        { fi === hoverItem && <FileContextMenus phaseId={props.phaseId} file={fi} folderId={folder?.id} />}
                                    </Stack>
                                </ListItemContent>   
                            </ListItemButton>                                                  
                        </ListItem>
                    )
                })
                }
            </List>
        </Box>
    )
}