import React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Typography from '@mui/joy/Typography';
import Box from '@mui/material/Box';
import GoogleMap from './GoogleMap';
import GoogleStaticMap, { IGoogleStaticMarker } from './GoogleStaticMap';

export interface IStaticMapDialogProps{
    title: string,
    centreLongitude: number,
    centreLatitude: number,
    zoomLevel: number,
    markers: {
        longitude:number,
        latitude:number,
        colour?:string|undefined
    }[],
    isOpen: boolean,
    onClose: ()=>void
}

export default function(props: IStaticMapDialogProps){


    return (
        <Modal open={props.isOpen} onClose={() => props.onClose()}>
            <ModalDialog  sx={{maxWidth: '650px', maxHeight:'700px'}}>
                <DialogTitle>{props.title}</DialogTitle>
                {/* <DialogContent>{props.title}</DialogContent> */}

                <Box sx={{ height: '100%', width: '100%' }}>
                    <GoogleStaticMap 
                        latitude={props.centreLatitude} 
                        longitude={props.centreLongitude} 
                        zoomLevel={props.zoomLevel} 
                        markers={props.markers.map(m => ({ latitude: m.latitude, longitude: m.longitude, colour: m.colour } as IGoogleStaticMarker))} 
                        width={600}
                        height={600}
                        />
                </Box>
            </ModalDialog>
        </Modal>
    )

}