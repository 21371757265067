import React, { useState } from 'react';
import { FileDto } from '../../api/useFileSystem';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import FormHelperText from '@mui/joy/FormHelperText';
import { useDeleteSustainabilityOpportunity, SustainableOpportunityDto } from '../../api/useSustainability';

export interface IDeleteOpportunityDialog{
    phaseId: string,    
    opportunity: SustainableOpportunityDto,
    isOpen: boolean,
    onClose: ()=>void
}

export default function(props: IDeleteOpportunityDialog){
    const [formError, setFormError] = useState<string>();
    const deleteOpportunity = useDeleteSustainabilityOpportunity();

    function onDelete(){    
        async function doWork(){
            if(props.opportunity.id){
                await deleteOpportunity(props.opportunity.id!);
                props.onClose();
            }
            else    
                setFormError("The opportunity has not been saved");
        }
        doWork();
    }

    return (
        <Modal open={props.isOpen} onClose={props.onClose}>
            <ModalDialog onClick={(e)=>e.stopPropagation()}>
                <Stack direction={'column'} gap={3}>
                    <Typography>Do you want to delete Sustainability Opportunity {props.opportunity?.referenceNumber}?</Typography>                    
                    <Button onClick={()=> onDelete()}>Delete</Button>
                    {!!formError && <FormHelperText>
                                {formError}
                            </FormHelperText>}
                </Stack>
            </ModalDialog>
        </Modal>
    )
}