import React from 'react';
import { IToolbarProps } from './FlexiFileView';
import Stack from '@mui/joy/Stack';
import { Select, Option, ButtonGroup, Button, Typography } from '@mui/joy';
import { FileDto } from '../../../api/useFileSystem';
import {  MapMarkerDto, SustainableOpportunityDto } from '../../../api/api.generated.clients';
import { IFileClickInfo } from '../../Files/FileViewer';
import { IFileMarker } from '../../Files/MarkerItemList';
import { IMapMarker, opportunityIcon } from '../../Maps/GoogleMap';
import { useDeleteMapPoint } from '../../../api/useRisks';
import OpportunityFilterSelectToolbar from '../../Sustainability/OpportunityFilterSelectToolbar';
import EditOpportunityForm from '../../Sustainability/EditOpportunityForm';
import { useGetOpportunityList, useDeleteMapPoint as useDeleteOpportunityMapPoint } from '../../../api/useSustainability';
import OpportunityMarkerOverlay from '../../Sustainability/OpportunityMarkerOverlay';


interface ICanResetFilters {
    resetFilters: () => void
}

export default function (props: IToolbarProps) {
    const [showCreateOpportunityForm, setShowCreateOpportunityForm] = React.useState<boolean>(false);
    const [filteredOpportunities, setFilteredOpportunities] = React.useState<SustainableOpportunityDto[]>([]);
    const [opportunities, setOpportunities] = React.useState<SustainableOpportunityDto[]>([])
    const opportunitiesQuery = useGetOpportunityList(props.project.id ?? '');
    const deleteOpportunityMapMarker = useDeleteOpportunityMapPoint();

    React.useEffect(() => {
        if (opportunitiesQuery.data && !opportunitiesQuery.isPending && !opportunitiesQuery.isError && opportunitiesQuery.data.succeeded && opportunitiesQuery.data.data)
        {
            console.log('setting opportunities: ' + opportunitiesQuery.data.data.filter(r => r.phaseId === props.phaseId).length)
            setOpportunities(opportunitiesQuery.data.data.filter(r => r.phaseId === props.phaseId))
            setFilteredOpportunities(opportunitiesQuery.data.data.filter(r => r.phaseId === props.phaseId))
        }
    }, [opportunitiesQuery.isPending, opportunitiesQuery.isError, opportunitiesQuery.data, props.phaseId])


    React.useEffect(()=>{
        loadFileMarkersForOpportunities()
        loadMapMarkersForOpportunities()
    },[filteredOpportunities])

    React.useEffect(()=>{
        loadFileMarkersForOpportunities()
    },[props.file])

    function loadMapMarkersForOpportunities(){
        const opportunitiesAndMarkers = filteredOpportunities.map(o=> o.mapMarkers?.map(m=> ({opp: o, marker : m})) ?? [])
        .reduce((prev,curr)=>[...prev,...curr], []);

        const markers = opportunitiesAndMarkers.map(m=> ({
            latitude: m.marker.centreLatitude,
            longitude: m.marker.centreLongitude,
            id: m.marker.id,
            showOverlay: false,
            icon: opportunityIcon,
            colour: 'blue',
            overlayComponent: () => <OpportunityMarkerOverlay
                title={m.opp.referenceNumber}
                onDeleteMarker={() => doDeleteOpportunityMapMarker(m.marker, m.opp)} />,
        } as IMapMarker));

        props.setMapMarkers?.(markers)
    }

    function doDeleteOpportunityMapMarker(marker: MapMarkerDto, opportunity: SustainableOpportunityDto) {
        async function doWork() {
            const result = await deleteOpportunityMapMarker({ opportunityId: opportunity.id!, markerId: marker.id! })
            if (result.succeeded) {
                loadMapMarkersForOpportunities();
            }
        }
        doWork();
    }

    function loadFileMarkersForOpportunities() {
        const OpportunitiesAndMarkers = filteredOpportunities
            .map(r => r.fileMarkers?.filter(f => f.fileId === props.file?.id).map(m => ({ risk: r, markers: m })) ?? [])
            .reduce((prev, curr) => [...prev, ...curr], []);

        const fileMarkers = OpportunitiesAndMarkers.map(x => {
            return ({
                fileId: x.markers.fileId,
                id: x.markers.id,
                pageNumber: x.markers.pageNumber,
                xPos: x.markers.xPos,
                yPos: x.markers.yPos,
                itemId: x.risk.id,
                itemTitle: x.risk.referenceNumber,
                colour: 'blue',
                isRisk: false
            } as IFileMarker)

        })
        props.setFileMarkers?.(fileMarkers);
    }

    return (

        <Stack direction='row' sx={{ width: '100%' }} alignItems={'center'}>
                    
                    <ButtonGroup sx={{ ml: '10px' }}>
                        <Button onClick={() => setShowCreateOpportunityForm(true)}>Add Sustainability Opportunity</Button>
                    </ButtonGroup>
                    <OpportunityFilterSelectToolbar 
                        project={props.project} 
                        disabled={false} 
                        opportunities={opportunities} 
                        onOpportunitiesSet={o => setFilteredOpportunities(o)} 
                        onAddMarker={(o) => props.onPlaceOpportunityMarker?.(o)} />
                    <EditOpportunityForm 
                        isNew={true} 
                        isOpen={showCreateOpportunityForm} 
                        onClose={() => setShowCreateOpportunityForm(false)} 
                        phaseId={props.phaseId ?? ''} 
                        projectId={props.project.id ?? ''} />
                </Stack>
    )



}