import React, { ReactElement } from 'react';
import Box from '@mui/joy/Box';
import { useGetFile, FileDto, IQueryResponse, ResultOfFileDto } from '../../api/useFileSystem';
import { FileType, FolderDto } from '../../api/api.generated.clients';
import ImageFileViewer from './ImageFileViewer';
import PdfFileViewer from './PdfFileViewer';
import { IFileMarker } from './MarkerItemList';
import Typography from '@mui/joy/Typography';

export interface IFileViewerProps {
  phaseId: string,
  file: FileDto,
  addNote: boolean,
  onNoteAdded: () => void,
  onFileClicked: (e: IFileClickInfo) => void,
  fileMarkers: IFileMarker[],
  fileLabel?: ReactElement  
}

export interface IFileClickInfo {
  x: number,
  y: number,
  page: number,
  fileId: string
}

export default function (props: IFileViewerProps) {
  const fileQuery = useGetFile(props.phaseId, props.file.id!)
  const [file, setFile] = React.useState<FileDto>();

  React.useEffect(() => {
    if (!fileQuery?.isPending && fileQuery?.data && fileQuery.data.succeeded) {
      setFile(fileQuery.data.data)
    }
  }, [fileQuery, fileQuery?.isPending, fileQuery?.data])

  if (!props.file || !file)
    return <Box><Typography>Select a file to displayx</Typography></Box>

  if (props.file.fileType === FileType.PdfType)
    return <PdfFileViewer fileLabel={props.fileLabel} fileMarkers={props.fileMarkers} phaseId={props.phaseId} addNote={props.addNote} onNoteAdded={props.onNoteAdded} file={file} onFileClicked={props.onFileClicked} />

  if (props.file.fileType === FileType.ImageType || props.file.fileType === FileType.Video)
    return <ImageFileViewer phaseId={props.phaseId} file={file} />

  return <Box><Typography>Can not render file type</Typography></Box>

}

