import React from 'react';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import styled from '@emotion/styled';
import Add from '@mui/icons-material/Add';
import { Box, Typography } from '@mui/joy';
import { useCreateUser, CreateUserCommand } from '../../api/useUserAdmin';


const AddButton = styled(Button)(({ theme }) => ({
    width: '100%',
    height: '100%',
    borderRadius: '10px'
}))

export default function () {
    const createUser = useCreateUser();
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [firstName, setFirstName] = React.useState<string>();
    const [lastName, setLastName] = React.useState<string>();
    const [email, setEmail] = React.useState<string>();
    const [password, setPassword] = React.useState<string>();
    const [confirmPassword, setConfirmPassword] = React.useState<string>();
    const [validationErrors, setValidationErrors] = React.useState<(string | undefined)[]>();

    const onFormSubmitted = (event: React.FormEvent<HTMLFormElement>)=>{
        event.preventDefault();

        if(!firstName || !lastName){
            setValidationErrors(['You must enter your name']);
            return;
        }

        if(!email){
            setValidationErrors(['You must enter an email']);
            return;
        }

        if(!password){
            setValidationErrors(['You must enter a password']);
            return;
        }

        if(password !== confirmPassword){
            setValidationErrors(['The passwords do not match']);
            return;
        }
        
        const command = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password
        } as CreateUserCommand;

        const doWork = async ()=>{
            const errors = await createUser(command);
            if(errors)
                setValidationErrors(errors);
            else
                onClose()
        }
        doWork();        
    }    

    function onClose(){
        setIsOpen(false);
        setFirstName(undefined);
        setLastName(undefined);
        setEmail(undefined);
        setPassword(undefined);
        setConfirmPassword(undefined);
    }

    return (
        <Stack sx={{ width: '100%', height: '100%' }}>
            <AddButton onClick={()=>setIsOpen(true)} startDecorator={<Add />}>Create New User</AddButton>
            <Modal open={isOpen} onClose={onClose}>
                <ModalDialog>
                    <DialogTitle>Create new user</DialogTitle>
                    <DialogContent><Typography>Fill in the information of the project.</Typography></DialogContent>
                    <form onSubmit={onFormSubmitted}>
                        <Stack spacing={2}>
                            <FormControl>
                                <FormLabel>First Name</FormLabel>
                                <Input value={firstName} onChange={(e)=>setFirstName(e.target.value)} autoFocus required />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Last Name</FormLabel>
                                <Input required value={lastName} onChange={(e)=> setLastName(e.target.value)}/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input required value={email} onChange={(e)=> setEmail(e.target.value)}/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Password</FormLabel>
                                <Input type='password' required value={password} onChange={(e)=> setPassword(e.target.value)}/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Confirm Password</FormLabel>
                                <Input type='password' required value={confirmPassword} onChange={(e)=> setConfirmPassword(e.target.value)}/>
                            </FormControl>
                            {validationErrors?.map(x=> <Typography>{x}</Typography>)}
                            <Button type="submit">Submit</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>
        </Stack>
    )
}