import React, { useState } from 'react';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import FormHelperText from '@mui/joy/FormHelperText';
import { useDeleteProject, useGetProject} from '../../api/useProjects';
import { ProjectDto } from '../../api/api.generated.clients';
import { useNavigate } from "react-router-dom";


export interface IDeleteProjectDialogProps{
    projectId: string,    
    isOpen: boolean,
    onClose: ()=>void
}

export default function(props: IDeleteProjectDialogProps){
    const [formError, setFormError] = useState<string>();
    const getProjectQuery  = useGetProject(props.projectId);
    const [project, setProject] = React.useState<ProjectDto>();
    const navigate = useNavigate();
    const DeleteProject = useDeleteProject();

    React.useEffect(()=>{
        if(getProjectQuery.data && !getProjectQuery.isPending && !getProjectQuery.error){
            setProject(getProjectQuery.data);
        }
    },[getProjectQuery.data, getProjectQuery.isPending, getProjectQuery.error]);

    function onDelete(){    
        async function doWork(){
            var result = await DeleteProject(props.projectId);
            if(result.succeeded){
                props.onClose();
                navigate('/projects');
            }
            else{
                setFormError(result.errors?.join('. '));
            }
        }
        doWork();
    }

    return (
        <Modal open={props.isOpen} onClick={(e)=>e.stopPropagation()} onClose={props.onClose}>
            <ModalDialog>
                <Stack direction={'column'} gap={3}>
                    <Typography>Do you want to delete Project {project?.projectName}?</Typography>                    
                    <Button onClick={()=> onDelete()}>Delete</Button>
                    {!!formError && <FormHelperText>
                                {formError}
                            </FormHelperText>}
                </Stack>
            </ModalDialog>
        </Modal>
    )
}