import React from 'react';
import { useGetUser, useDeleteUser, GetUserDto } from '../../api/useUserAdmin';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';


export interface IConfirmDeleteDialog{
    userId: string,    
    isOpen: boolean,
    onClose: ()=>void
}

export default function(props: IConfirmDeleteDialog){
    const [user, setUser] = React.useState<GetUserDto | undefined>();
    const userQuery = useGetUser(props.userId);
    const deleteUser = useDeleteUser();

    React.useEffect(()=>{
        if(userQuery.data)
            setUser(userQuery.data);

    },[userQuery.isPending, userQuery.data])

    function onDelete(){
        deleteUser(props.userId);
        props.onClose();
    }

    return (
        <Modal open={props.isOpen} onClose={props.onClose}>
            <ModalDialog>
                <Stack direction={'column'} gap={3}>
                    <Typography>Do you want to delete user {user?.firstName} {user?.lastName}</Typography>
                    <Typography>{user?.email}</Typography>
                    <Button onClick={()=> onDelete()}>Delete</Button>
                </Stack>
            </ModalDialog>
        </Modal>
    )
}