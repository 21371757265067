interface LikelihoodRating{
    Score: number,
    Name: string,
    Description: string,
    PercentageProbability: string
}

interface ConsequenceRating{
    Score: number,
    Name: string,
    SafetyImpact: string,
    ReputationImpact: string,
    FinancialImpact: string,
    ComplianceImpact:string,
    EnvironmentalImpact: string,
    FunctionalImpact:string,
    DelayImpact:string
}

const LikelihoodOptions : LikelihoodRating[] = [
    {
        Score: 1,
        Name: "Very Rare",
        PercentageProbability: '< 1',
        Description:'The event has occurred infrequently before, but it is not anticipated on this project.'
    },
    {
        Score: 2,
        Name: "Rare",
        PercentageProbability: '1 - 10',
        Description:'The event has occurred infrequently before'
    },
    {
        Score: 3,
        Name: 'Moderate',
        PercentageProbability:'10 - 50',
        Description:'The occurrence of the event is unusual.'
    },
    {
        Score: 4,
        Name: 'Above Average',
        PercentageProbability: '50 - 99',
        Description:'The occurrence of the event would not be considered unusual. The event has occurred in the past few years. Circumstances are in train that may cause the event to happen.'
    },
    {
        Score: 5,
        Name: 'Almost Certain',
        PercentageProbability: '> 99',
        Description: 'The event can be expected to occur in most circumstances. The event has occurred in the past (at least annually). Circumstances are in train that will cause the event to happen.'
    }
]

const ConsequenceOptions: ConsequenceRating[] = [
    {
        Score: 1,
        Name: 'Insignificant',
        SafetyImpact: 'Ailments not requiring treatment',
        ReputationImpact: 'Self-improvement review required. No media exposure.',
        FunctionalImpact: 'Insignificant impact on planned outcomes',
        ComplianceImpact: 'Breaches rectified without ongoing issues',
        DelayImpact: '< 1 Week',
        EnvironmentalImpact: 'Insignificant environmental impact',
        FinancialImpact: '< $100,000'
    },
    {
        Score: 2,
        Name: 'Minor',
        SafetyImpact: 'Injury or illness but the person is able to return to normal duties either immediately or by the beginning of their next shift',
        ReputationImpact: 'Internal reviews required to reverse decline in reputation. Reported in Regional/Community papers',
        FunctionalImpact: 'Some impact on planned outcomes, barely measurable',
        ComplianceImpact: 'Notifications to Authorities',
        DelayImpact: '<2 Weeks',
        EnvironmentalImpact: 'Immediately contained environmental impact',
        FinancialImpact: '< $250,000'
    },
    {
        Score: 3,
        Name: 'Moderate',
        SafetyImpact: 'Temporary impairment (medical treatment required) - person will recover to undertake normal duties at the beginning of their next shift.',
        ReputationImpact: 'Scrutiny required by external reviews and/or investigations. State exposure - mainstream papers, not front page',
        FunctionalImpact: 'Measurable impact on planned outcomes',
        ComplianceImpact: 'Breach of Act/Regulation leading to an improvement notice being issued',
        DelayImpact: '< 1 Month',
        EnvironmentalImpact: 'Contained environmental impact',
        FinancialImpact: '< $500,000'
    },
    {
        Score: 4,
        Name: 'Major',
        SafetyImpact: 'Permanent impairment and/or disability of a result of injury or illness. Multiple cases of Moderate consequences',
        ReputationImpact: 'Public, political and media scrutiny evidenced by front page headlines of State papers and/or television coverage',
        FunctionalImpact: 'Serious impact on planned outcomes',
        ComplianceImpact: 'Breach of Act/Regulation leading to a penalty notice',
        DelayImpact: '<3 Months',
        EnvironmentalImpact: 'Localised uncontained environmental impact',
        FinancialImpact: '< $1,000,000'
    },
    {
        Score: 5,
        Name: 'Catastrophic',
        SafetyImpact: 'Fatality as a result of injury or illness. Multiple cases of Major consequences',
        ReputationImpact: 'Intense public, political and media scrutiny evidenced by National median exposure ',
        FunctionalImpact: 'Major impact on planned outcomes',
        ComplianceImpact: 'Breach of Act/Regulation leading to an improvement notice being issued',
        DelayImpact: '> 3 Months',
        EnvironmentalImpact: 'Uncontained environmental impact',
        FinancialImpact: '<$5,000,000'
    }
]

class LikelihoodScore{
    Rating : LikelihoodRating | undefined;

    constructor(score: number | undefined){
        this.Rating = LikelihoodOptions.find(x=>x.Score === score);
    }
    GetName = ()=> {
        if(!this.Rating)
            return '';

        return this.Rating.Name ?? 'Unknown';
    }

    GetScore = ()=> {
        if(!this.Rating)
            return 0;

        return this.Rating.Score;
    }
}

class ConsequenceScore{
    private Rating: ConsequenceRating | undefined;

    constructor(score: number | undefined){
        this.Rating = ConsequenceOptions.find(x=>x.Score === score);
    }
    GetName = ()=> {
        if(!this.Rating)
            return '';
        
        return this.Rating.Name ?? 'Unknown';
    }

    GetScore = ()=> {
        if(!this.Rating)
            return 0;

        return this.Rating.Score;
    }
}

class RiskScore{
    constructor(likelihood: number, consequence: number){
        this.Likelihood = new LikelihoodScore(likelihood);
        this.Consequence = new ConsequenceScore(consequence);
    }

    Likelihood: LikelihoodScore;
    Consequence: ConsequenceScore;

    TotalScore = () => this.Likelihood.GetScore() * this.Consequence.GetScore();
    OverallSeverity = () => {
        const score = this.TotalScore();
        if(score < 8)
            return 1;
        else if(score < 15)
            return 2;
        else if(score < 20)
            return 3;
        else
            return 4;
    }
    
}
export type { LikelihoodRating, ConsequenceRating }
export { LikelihoodOptions, ConsequenceOptions, LikelihoodScore, ConsequenceScore, RiskScore }