
import { extendTheme } from '@mui/joy/styles';


declare module '@mui/joy/styles' {
  // No custom tokens found, you can skip the theme augmentation.
}

declare module '@mui/joy/styles' {
  interface PaletteTextOverrides {
    invertPrimary: true;
    invertSecondary: true;
    invertTertiary: true;
    invertIcon: true;
  }
}

const theme = extendTheme({
  "fontFamily":{
    "body": "'Segoe UI Local', 'Segoe UI Web (West European)', var(--joy-fontFamily-fallback)",
  },
  "colorSchemes": {    
    "light": {
      "palette": {
        
        "primary": {          
          solidBg: '#1dcd8d',       // the initial background
          solidColor: '#d5fdff',                              // the initial color
          solidHoverBg: '#1dcd8d',  // the :hover background
          solidActiveBg: 'var(--joy-palette-primary-800)', // the :active background
          // solidBorder: '#86eaeb',
          softBg: '#e8fff7',
          // softColor: '#000000',          
          // softBorder: '',
          outlinedBg: '#f4f4f4',
          outlinedBorder: ''
          // "50": "#8E678C",
          // "100": "#825E80",
          // "200": "#765675",
          // "300": "#6A4D69",
          // "400": "#5F455D",
          // "500": "#493548",
          // "600": "#473346",
          // "700": "#3B2B3A",
          // "800": "#283593",
          // "900": "#231A23",
          // "plainActiveBg": "var(--joy-palette-primary-100)",
          // "plainHoverColor": "var(--joy-palette-primary-50)"
        },
        "neutral": {
          // "50": "#ACC0C3",
          // "100": "#A0B7BA",
          // "200": "#95AEB2",
          // "300": "#89A6A9",
          // "400": "#7D9DA1",
          // "500": "#6A8D92",
          // "600": "#67898E",
          // "700": "#5E7E82",
          // "800": "#567376",
          // "900": "#4D676A",
          // "plainActiveBg": "var(--joy-palette-neutral-50)",
          // "plainHoverColor": "var(--joy-palette-neutral-50)"
        },
        "danger": {
          // "50": "#E4F8DD",
          // "100": "#D7F5CC",
          // "200": "#C9F2BA",
          // "300": "#BCEFA9",
          // "400": "#AEEB98",
          // "500": "#A1E887",
          // "600": "#94E576",
          // "700": "#86E265",
          // "800": "#79DE54",
          // "900": "#6BDB43",
          // "plainActiveBg": "var(--joy-palette-danger-100)",
          // "plainHoverColor": "var(--joy-palette-danger-50)"
        },
        "success": {
          // "50": "#7B7EA3",
          // "100": "#6F729B",
          // "200": "#646890",
          // "300": "#5C5F84",
          // "400": "#545778",
          // "500": "#4B4E6D",
          // "600": "#434560",
          // "700": "#3B3D54",
          // "800": "#323448",
          // "900": "#2A2B3C",
          // "plainActiveBg": "var(--joy-palette-success-100)",
          // "plainHoverColor": "var(--joy-palette-success-50)"
        },
        "warning": {
          // "50": "#C0D8C9",
          // "100": "#B3D0BE",
          // "200": "#A6C9B3",
          // "300": "#9AC1A8",
          // "400": "#8DB99D",
          // "500": "#80B192",
          // "600": "#74AA88",
          // "700": "#67A27D",
          // "800": "#5D9873",
          // "900": "#558B69",
          // "plainActiveBg": "var(--joy-palette-warning -100)",
          // "plainHoverColor": "var(--joy-palette-warning-50)"
        },
        "common":{
          // "white": "var(--joy-palette-neutral-50)"
        },
        "background":{
          // "body": "var(--joy-palette-common-white)",
          // "surface": "var(--joy-palette-common-white)",
          // "popup": "var(--joy-palette-common-white)",
          // "level1": "var(--joy-palette-common-white)",
          // "level2": "var(--joy-palette-common-white)",
          // "level3": "var(--joy-palette-common-white)",
          // "tooltip": "var(--joy-palette-common-white)",
          // "backdrop": "rgba(var(--joy-palette-common-white), 11 13 14) / 0.25)"
        },
        "text": {
          // "invertPrimary": "var(--joy-palette-neutral-200)",
          // "invertSecondary": "var(--joy-palette-neutral-100)",
          // "invertTertiary": "var(--joy-palette-neutral-100)",
          // "invertIcon": "var(--joy-palette-neutral-100)",
          // "primary": "var(--joy-palette-neutral-600)",
          // "secondary": "var(--joy-palette-neutral-600)",
          // "tertiary": "var(--joy-palette-neutral-900)",
          // "icon": "var(--joy-palette-neutral-300)"
        }      
    }
  },
    "dark": {
      "palette": {}
    }
  },
  "typography":{
    "h2":{
      fontSize: '1.4rem',
      fontWeight: '600',
    },
    "h3":{
      fontSize: '0.9rem',
      fontWeight: '400'
    },
    "h4":{
      fontSize: "0.8rem",
      fontWeight: '300'
    },
    "title-lg":{
      fontSize: '0.9rem',
      fontWeight: '600'
    },
    "title-md":{
      fontSize: '0.8rem',
      fontWeight: '600'
    },
    "title-sm":{
      fontSize: '0.7rem',
      fontWeight: '600'
    },
    "body-lg":{
      fontSize: '0.9rem'
    },
    "body-md":{
      fontSize: '0.8rem'
    },
    "body-sm":{
      fontSize: '0.7rem'
    },
    "body-xs":{
      fontSize: '0.6rem'
    }
  }
})

export default theme;