import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { RiskClient, RiskItemDto, SaveRiskItemCommand, AddMapPointToRiskCommand, Result, AddFilePointToRiskCommand, RiskActionDto, SaveRiskActionCommand } from './api.generated.clients';

export interface IQueryResponse<T> {
    isPending: boolean,
    data: T | undefined,
    error: Error
}

export interface IRiskApi {
    saveRisk: (command: SaveRiskItemCommand) => Promise<RiskItemDto | null>,
    getRisks: (projectId: string | undefined) => IQueryResponse<RiskItemDto[]>,
    getRisk: (riskId: string) => IQueryResponse<RiskItemDto | null>,
    deleteRisk: (riskId: string) => Promise<void>,
    addMapMarker: (cmd: AddMapPointToRiskCommand) => Promise<Result>,
    deleteMapMarker: (riskId: string, markerId: string) => Promise<Result>,
    addFileMarker: (cmd: AddFilePointToRiskCommand) => Promise<Result>,
    deleteFileMarker: (riskId: string, fileMarkerId: string) => Promise<Result>
}

const riskClient = new RiskClient();

const useDeleteRisk = () => {
    const queryClient = useQueryClient();
    const deleteRiskMutation = useMutation({
        mutationFn: async (riskId: string) => riskClient.riskDelete(riskId),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['risk', variables] });
            queryClient.invalidateQueries({ queryKey: ['risks'] })
        }
    })

    return deleteRiskMutation.mutateAsync;
}



const useSaveRisk = () => {
    const queryClient = useQueryClient();
    const saveRiskMutation = useMutation({
        mutationFn: async (risk: SaveRiskItemCommand) => {
            var riskResult = await riskClient.riskPost(risk)
            return riskResult;
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['risk', variables.id] });
            queryClient.invalidateQueries({ queryKey: ['risks'] })
        }
    });

    return saveRiskMutation.mutateAsync;
}


const useAddMapPoint = () => {
    const queryClient = useQueryClient();
    const addMapPointMutation = useMutation({
        mutationFn: (cmd: AddMapPointToRiskCommand) => riskClient.mapmarkerPost(cmd),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['risk', variables.riskId] });
            queryClient.invalidateQueries({ queryKey: ['risks'] });
        }
    });
    return addMapPointMutation.mutateAsync;
}



const useAddFilePoint = () => {
    const queryClient = useQueryClient();
    const addFilePointMutation = useMutation({
        mutationFn: (cmd: AddFilePointToRiskCommand) => riskClient.filemarkerPost(cmd),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['risk', variables.riskId] });
            queryClient.invalidateQueries({ queryKey: ['risks'] });
        }
    })
    return addFilePointMutation.mutateAsync;
}



const useDeleteFilePoint = () => {
    const queryClient = useQueryClient();
    const deleteFilePointMutation = useMutation({
        mutationFn: ({ riskId, filepointId }: { riskId: string, filepointId: string }) => riskClient.filemarkerDelete(riskId, filepointId),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['risk', variables.riskId] });
            queryClient.invalidateQueries({ queryKey: ['risks'] });
        }
    });
    return deleteFilePointMutation.mutateAsync;
}



const useDeleteMapPoint = () => {
    const queryClient = useQueryClient();
    const deleteMapPointMutation = useMutation({
        mutationFn: ({ riskId, markerId }: { riskId: string, markerId: string }) => riskClient.mapmarkerDelete(riskId, markerId),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['risk', variables.riskId] });
            queryClient.invalidateQueries({ queryKey: ['risks'] });
        }
    });
    return deleteMapPointMutation.mutateAsync;
}

const useGetRisks = (projectId: string) => {
    return useQuery({ queryKey: ['risks', projectId], queryFn: () => riskClient.list(projectId) });
}

const useGetRisk = (id: string) => {
    return useQuery({
        queryKey: ['risk', id],
        queryFn: () => riskClient.riskGet(id),
        enabled: !!id && id !== ''
    })
}

const useSaveRiskAction = () => {
    const queryClient = useQueryClient();
    const saveRiskActionMutation = useMutation({
        mutationFn: (cmd: SaveRiskActionCommand) => riskClient.actionPost(cmd),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['risk', variables.riskId] });
            queryClient.invalidateQueries({ queryKey: ['risks', variables.phaseId] });
        }
    });
    return saveRiskActionMutation.mutateAsync;
}

const useDeleteRiskAction = () => {
    const queryClient = useQueryClient();
    const deleteRiskActionMutation = useMutation({
        mutationFn: ({ riskId, actionId }: { riskId: string, actionId: string }) => riskClient.actionDelete(riskId, actionId),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['risk', variables.riskId] });
            queryClient.invalidateQueries({ queryKey: ['risks'] });
        }
    });
    return deleteRiskActionMutation.mutateAsync;
}


export type { RiskItemDto, SaveRiskItemCommand, AddMapPointToRiskCommand, AddFilePointToRiskCommand, RiskActionDto, SaveRiskActionCommand };
export { 
    useSaveRisk, 
    useGetRisk, 
    useGetRisks, 
    useDeleteRisk, 
    useAddMapPoint, 
    useDeleteMapPoint, 
    useAddFilePoint, 
    useDeleteFilePoint, 
    useSaveRiskAction, 
    useDeleteRiskAction }