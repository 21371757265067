import React from 'react';
import Stack from '@mui/joy/Stack';
import IconButton from '@mui/joy/IconButton';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import SettingsIcon from '@mui/icons-material/Settings';
import Delete from '@mui/icons-material/Delete';
import moment, { Moment } from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { useGetProject, useSaveProject, SaveProjectCommand, AssignProjectUsersCommand, useAssignProjectUsers, useSavePhase, useDeletePhase } from '../../api/useProjects';
import { ProjectDto, ProjectPhaseDto, ProjectUserDto, SaveProjectPhaseCommand } from '../../api/api.generated.clients';
import ProjectPermissionsButton from './ProjectPermissionsButton';
import DeleteProjectDialog from './DeleteProjectDialog';
import ProjectDetailsForm from './ProjectDetailsForm';
import ProjectMapSelector from './ProjectMapSelector';
import AssignProjectUsersForm from './AssignProjectUsersForm';
import ManagePhasesView from './ManagePhasesView';
import Tabs from '@mui/joy/Tabs';
import Tab from '@mui/joy/Tab';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import { Button, Typography } from '@mui/joy';
import { IGoogleMapSnapshot } from '../Maps/GoogleMap';


export interface IProjectSettingsButtonProps {
    projectId: string
}

export default function (props: IProjectSettingsButtonProps) {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [project, setProject] = React.useState<ProjectDto>();
    const getProjectQuery = useGetProject(props.projectId);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);
    const SaveProject = useSaveProject();
    const assignUsers = useAssignProjectUsers();
    const savePhase = useSavePhase();
    const deletePhase = useDeletePhase();
    const [saveProjectCommand, setSaveProjectCommand] = React.useState<SaveProjectCommand>({});
    const [assignUsersCommand, setAssignUsersCommand] = React.useState<AssignProjectUsersCommand>();
    const [phases, setPhases] = React.useState<ProjectPhaseDto[]>([]);
    const [phasesToDelete, setPhasesToDelete] = React.useState<ProjectPhaseDto[]>([])
    

    React.useEffect(() => {
        console.log(JSON.stringify(getProjectQuery.data))
        if (isOpen && getProjectQuery && getProjectQuery.data && !getProjectQuery.isPending && !getProjectQuery.error) {
            console.log(`fixinL: ${JSON.stringify(getProjectQuery.data)}`)
            setProject(getProjectQuery.data);
            setAssignUsersCommand({targetProjectId: props.projectId, mappings: getProjectQuery.data.projectUsers?.map(x=> ({userId: x.userId, canWrite: x.canWrite, isAdmin: x.isAdmin}))})
            setSaveProjectCommand({ ...saveProjectCommand, ...getProjectQuery.data, projectId: props.projectId })
        }

    }, [getProjectQuery.data, getProjectQuery.isPending, isOpen])


    const onSaveClicked = () => {
        async function doWork() {
            if (saveProjectCommand.projectName) {
                const result = await SaveProject(saveProjectCommand);
                if (!!assignUsersCommand) {
                    await assignUsers({ ...assignUsersCommand, targetProjectId: result })
                }
                if(!!phases){
                    phases.forEach(async p => {
                        await savePhase({...p, projectId: props.projectId, phaseId: p.id} as SaveProjectPhaseCommand);
                    });
                }
                // if(!!phasesToDelete){
                    phasesToDelete.forEach(async p=>{
                        await deletePhase({projectId: props.projectId, phaseId: p.id!})
                    })
                // }
                setPhasesToDelete([]);
            }

            setIsOpen(false);
        }
        doWork();
    }

    function onClosing() {
        setSaveProjectCommand({ ...getProjectQuery.data, projectId: props.projectId })
        setProject(undefined)
        setPhasesToDelete([]);
        setIsOpen(false);
    }

    function onMapUpdated(snapshot: IGoogleMapSnapshot) {
        console.log('map updated: ' + JSON.stringify(snapshot))
        setSaveProjectCommand({
            ...saveProjectCommand,
            centreLatitude: snapshot.latitude,
            centreLongitude: snapshot.longitude,
            zoomLevel: snapshot.zoomLevel,
            projectId: props.projectId
        })
    }

    function onFormUpdated(cmd: SaveProjectCommand) {
        setSaveProjectCommand({ ...saveProjectCommand, ...cmd })
    }

    function onPhasesUpdate(updatedPhases: ProjectPhaseDto[]){
        console.log('phases updated')
        setPhases(updatedPhases)
    }

    return (
        <Stack>
            <IconButton variant='outlined' color='primary' onClick={() => setIsOpen(true)}><SettingsIcon /></IconButton>
            <Modal open={isOpen} onClose={onClosing}>
                <ModalDialog sx={{ width: '800px', height: '800px', overflow: 'auto' }}>
                    <DialogTitle>Project Settings</DialogTitle>
                    <Stack direction='column' sx={{ width: '100%', height: '100%' }} spacing={2} justifyContent={'space-between'}>
                        <Tabs sx={{ height: '100%'}}>
                            <TabList>
                                <Tab variant='soft' color='primary'>Location</Tab>
                                <Tab variant='soft' color='primary'>Details</Tab>
                                <Tab variant='soft' color='primary'>Assign Users</Tab>
                                <Tab variant='soft' color='primary'>Phases</Tab>
                            </TabList>
                            <TabPanel value={0}>
                                <ProjectMapSelector latitude={project?.centreLatitude} longitude={project?.centreLongitude} zoomLevel={project?.zoomLevel} onUpdated={onMapUpdated} />
                            </TabPanel>
                            <TabPanel value={1}>
                                <ProjectDetailsForm project={project} onUpdated={onFormUpdated} />
                            </TabPanel>
                            <TabPanel value={2}>
                                <AssignProjectUsersForm project={{ projectName: saveProjectCommand.projectName, projectUsers: assignUsersCommand?.mappings?.map(x => ({ userId: x.userId, canWrite: x.canWrite, isAdmin: x.isAdmin } as ProjectUserDto)) } as ProjectDto} onUpdated={cmd => setAssignUsersCommand(cmd)} />
                            </TabPanel>
                            <TabPanel sx={{ height: '100%'}} value={3}>
                                <ManagePhasesView projectId={props.projectId} phases={project?.phases ?? []} onUpdated={onPhasesUpdate} onDelete={p=>setPhasesToDelete([...phasesToDelete, p])}/>
                            </TabPanel>
                        </Tabs>
                        <Stack direction='row' justifyContent={'space-between'}>
                            <Stack direction='row' justifyContent={'flex-start'}>
                                <Button onClick={() => setIsDeleteDialogOpen(true)} variant={'outlined'}>Delete Project</Button>
                            </Stack>
                            <Stack direction='row' spacing={2}>
                                <Button variant='outlined' onClick={() => onClosing()}>Cancel</Button>
                                <Button variant='solid' onClick={(e) => onSaveClicked()}>Save</Button>
                            </Stack>
                        </Stack>
                    </Stack>
                    <DeleteProjectDialog isOpen={isDeleteDialogOpen} projectId={props.projectId} onClose={() => setIsDeleteDialogOpen(false)} />
                </ModalDialog>
            </Modal>
        </Stack>
    )
}