import React from 'react';
import Note, { INoteProps, INoteUpdatedEvent } from './Note';
import { IFileMarker } from '../Files/MarkerItemList';
import Marker from './Marker';
import { PropsWithChildren } from 'react'
import { Box } from '@mui/joy';

export interface IAnnotationsProps {
    notes: INoteProps[],
    zoomScale?: number | undefined,
    markers: IFileMarker[],
    onNoteSaved?: (e: INoteUpdatedEvent) => void
    onBoardClicked: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export default function (props: PropsWithChildren<IAnnotationsProps>) {
    const [notes, setNotes] = React.useState<INoteProps[]>([]);
    const [markers, setMarkers] = React.useState<IFileMarker[]>([])
    const [selectedMarker, setSelectedMarker] = React.useState<IFileMarker>();

    React.useEffect(() => {
        // setNotes(props.notes);
        setMarkers(props.markers);
    }, [props.markers])


    const styles: React.CSSProperties = {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 999
    }

    return (
        <Box sx={{ position: 'relative', margin: '20px' }} onClick={props.onBoardClicked}>
                {props.children}

                <Box sx={{ position: 'absolute' }}>
                    {markers.map(x => <Marker
                        onClick={() => setSelectedMarker(x)}
                        x={x.xPos!}
                        y={x.yPos!}
                        zoomScale={props.zoomScale || 1}
                        showOverlay={x === selectedMarker}
                        itemId={x.itemId}
                        text={x.itemTitle}
                        id={x.id}
                        colour={x.colour} showAlternateMarker={!x.isRisk} />)}
                </Box>

            {/* { notes.map(x=> <Note onNoteUpdated={(e)=> props.onNoteSaved?.(e)} zoomScale={props.zoomScale || 1} {...x}/>)} */}
        </Box>
    )
}