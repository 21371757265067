import React from 'react';
import Card from '@mui/joy/Card';
import Input from '@mui/joy/Input';
import FormLabel from '@mui/joy/FormLabel';
import FormControl from '@mui/joy/FormControl';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import useSecurityApi, { ResetPasswordCommand } from '../api/useSecurityApi';
import { useSearchParams } from 'react-router-dom';
import FormHelperText from '@mui/joy/FormHelperText';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/joy/styles';
import { useNavigate } from "react-router-dom";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginBottom: '10px'
}))

export default function(){
    const [currentPassword, setCurrentPassword] = React.useState<string >('');
    const [newPassword, setNewPassword] = React.useState<string >('');
    const [confirmNewPassword, setConfirmNewPassword] = React.useState<string >('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [failFlag, setFailFlag] = React.useState<boolean>();
    const [failMessage, setFailMessage] = React.useState<string>('');
    const user = searchParams.get('user');
    const auth = searchParams.get('auth');
    const { ResetPassword } = useSecurityApi();
    const navigate = useNavigate();

    const onReset = () => {
        async function doReset() {
            if(newPassword && newPassword !== confirmNewPassword)
            {
                setFailFlag(true);
                setFailMessage('The entered passwords do not match');
            }
            else
            {
                const result = await ResetPassword({
                    userId : user,
                    resetPasswordToken : auth,
                    currentPassword: currentPassword,
                    newPassword: newPassword
                } as ResetPasswordCommand);
                if(!result.succeeded && result.errors){
                    setCurrentPassword('');
                    setNewPassword('');
                    setConfirmNewPassword('');
                    setFailFlag(true);
                    setFailMessage(result.errors.reduce((all, curr)=> all + ', ' + curr, ''));
                }
                else{
                    navigate({pathname:'/'})
                }
            }
        }
        doReset();
    }

    const onFormSubmit = (e: any) => {
        onReset();
        e.preventDefault();
        e.stopPropagation();
    }

    return (
        <Card sx={{height: '300px', width: '300px', alignSelf: 'center'}} variant='outlined'>
            <form onSubmit={onFormSubmit}>
                <Stack direction={'column'}>
                    { !auth && <StyledFormControl>
                        <FormLabel>Current Password</FormLabel>
                        <Input autoFocus required value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} />
                    </StyledFormControl> }
                    <StyledFormControl>
                        <FormLabel>New Password</FormLabel>
                        <Input type='password' required value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                    </StyledFormControl>
                    <StyledFormControl>
                        <FormLabel>Confirm New Password</FormLabel>
                        <Input type='password' required value={confirmNewPassword} onChange={e => setConfirmNewPassword(e.target.value)} />
                    </StyledFormControl>
                    <Stack>
                        <StyledFormControl color='danger' error={failFlag}>
                            {failFlag && <FormHelperText color='danger'>
                                <InfoOutlined />
                                {failMessage}
                            </FormHelperText>}
                            <Button type='submit'>Reset Password</Button>
                        </StyledFormControl>
                    </Stack>
                </Stack>
            </form>
        </Card>
    )
}