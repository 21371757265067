import { GoogleMap, MarkerF, OverlayViewF, useJsApiLoader } from '@react-google-maps/api';
import React, { ReactElement } from 'react';
import { IOverlayProps } from '../Annotations/IOverlayProps';

interface IGoogleMapWidgetProps extends Partial<IMapPoint> {
    zoomLevel?: number,
    onMapClick?: (((props: IGoogleMapSnapshot) => void) | undefined | null),
    onMouseUp?: (((props: IGoogleMapSnapshot) => void) | undefined | null),
    onMapViewChanged?: (((props: IGoogleMapSnapshot) => void) | undefined | null),
    markers?: IMapMarker[] | undefined
}

export interface IGoogleMapSnapshot extends IMapPoint {
    zoomLevel: number | undefined
}

export interface IGoogleMapRef {
    GetMapData: () => IGoogleMapSnapshot
}

export interface IMapMarker extends IMapPoint {
    id?: string | undefined
    showOverlay?: boolean | false,
    onClick?: (() => void) | undefined,
    overlayComponent?: (() => ReactElement<IOverlayProps>) | undefined,
    colour?: string | undefined,
    icon? : any
}

export interface IMapPoint {
    longitude: number,
    latitude: number
}

export const riskIcon = {
    path: 'M 12 2 C 8.13 2 5 5.13 5 9 c 0 5.25 7 13 7 13 s 7 -7.75 7 -13 c 0 -3.87 -3.13 -7 -7 -7 m 0 9.5 c -1.38 0 -2.5 -1.12 -2.5 -2.5 s 1.12 -2.5 2.5 -2.5 s 2.5 1.12 2.5 2.5 s -1.12 2.5 -2.5 2.5',
    fillColor: 'red',
    fillOpacity: 1,
    strokeColor: 'white',
    strokeWeight: 1,
    scale: 2,
    anchor: {x: 11, y: 21} as google.maps.Point,
}

export const opportunityIcon = {
    path: 'M12 2C8.14 2 5 5.14 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.86-3.14-7-7-7m4 8h-3v3h-2v-3H8V8h3V5h2v3h3z',
    fillColor: 'red',
    fillOpacity: 1,
    strokeColor: 'white',
    strokeWeight: 1,
    scale: 2,
    anchor: {x: 11, y: 21} as google.maps.Point,
}

function GoogleMapWidget(props: IGoogleMapWidgetProps, ref: React.ForwardedRef<IGoogleMapRef>) {
    const [mapState, setMap] = React.useState<google.maps.Map | null>(null);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_Google_Map_Api_Key
    });

    React.useEffect(()=>{
        
        if(mapState && props.latitude && props.longitude && props.zoomLevel ){
            // google.maps.event.
            mapState.setCenter({lat: props.latitude, lng: props.longitude});
            mapState.setZoom(props.zoomLevel);
        }
    },[props.latitude, props.longitude, props.zoomLevel])


    const onLoad = React.useCallback(function callback(map: google.maps.Map) {
        if(props.latitude && props.longitude && props.zoomLevel){
            map.setCenter({ lat: props.latitude, lng: props.longitude });
        }
        map.setOptions({ draggableCursor: 'default' });
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
        setMap(null)
    }, [])


    const containerStyle: React.CSSProperties = {
        width: '100%',
        height: '100%',
        position: 'fixed'
    };


    React.useImperativeHandle(ref, () => ({
        GetMapData() {
            var newCenter = mapState?.getCenter();
            var newZoom = mapState?.getZoom();
            return {
                latitude: newCenter?.lat(),
                longitude: newCenter?.lng(),
                zoomLevel: newZoom
            } as IGoogleMapSnapshot
        }
    }))

    function OnClick(e: google.maps.MapMouseEvent) {
        if (props.onMapClick)
            props.onMapClick({
                latitude: e.latLng?.lat(),
                longitude: e.latLng?.lng(),
                zoomLevel: mapState?.getZoom()
            } as IGoogleMapSnapshot)
    }

    function OnMouseUp(e: google.maps.MapMouseEvent) {
        if (props.onMouseUp)
            props.onMouseUp({
                latitude: e.latLng?.lat(),
                longitude: e.latLng?.lng(),
                zoomLevel: mapState?.getZoom()
            } as IGoogleMapSnapshot)
    }

    function OnMapViewChanged(){
        if(!mapState)
            return;

        const centre = mapState?.getCenter();
        if(props.onMapViewChanged && (centre?.lat() !== props.latitude || centre?.lng() !== props.longitude || mapState?.getZoom() !== props.zoomLevel) ){
            props.onMapViewChanged({
                latitude: centre?.lat(),
                longitude: centre?.lng(),
                zoomLevel: mapState?.getZoom()
            } as IGoogleMapSnapshot)
        }
    }

    function OnZoomChanged(){
        // console.log('zoomed');
        OnMapViewChanged();
    }

    const icon = {
        path: 'M 12 2 C 8.13 2 5 5.13 5 9 c 0 5.25 7 13 7 13 s 7 -7.75 7 -13 c 0 -3.87 -3.13 -7 -7 -7 m 0 9.5 c -1.38 0 -2.5 -1.12 -2.5 -2.5 s 1.12 -2.5 2.5 -2.5 s 2.5 1.12 2.5 2.5 s -1.12 2.5 -2.5 2.5',
        fillColor: 'red',
        fillOpacity: 1,
        strokeColor: 'white',
        strokeWeight: 1,
        scale: 2,
        anchor: {x: 11, y: 21} as google.maps.Point,
    }

    

    return isLoaded ? (

        <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={props.zoomLevel}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={OnClick} 
            // onMouseUp={OnMouseUp}
            // onZoomChanged={OnZoomChanged}
            onBoundsChanged={OnMapViewChanged}
        >

            {props.markers && props.markers.map((x, i) => {
                const overlay = (x.showOverlay && x.overlayComponent) ? x.overlayComponent() : null;
                return (<div key={i}>
                    <MarkerF
                        onClick={() => x.onClick?.()} 
                        key={i}
                        icon={{...(x.icon ?? icon), fillColor: x.colour ?? 'red'}}
                        position={{ lat: x.latitude, lng: x.longitude }}
                    />
                    {x.showOverlay &&
                        <OverlayViewF key={`${i}b`} mapPaneName='floatPane' position={{ lat: x.latitude, lng: x.longitude }}>
                            {overlay}
                        </OverlayViewF>
                    }
                </div>)
            })}
            <></>
        </GoogleMap>

    ) : <></>
}

export default React.memo(React.forwardRef(GoogleMapWidget));