import React from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import Skeleton from '@mui/joy/Skeleton';
import Box from '@mui/joy/Box';

interface IGoogleStaticMapProps {
    latitude: number | undefined,
    longitude: number | undefined,
    zoomLevel: number | undefined,
    width: number,
    height: number,
    markers?: IGoogleStaticMarker[]
}

export interface IGoogleStaticMarker {
    latitude: number,
    longitude: number,
    colour?: string | undefined
}

const GoogleStaticMap: React.FC<IGoogleStaticMapProps> = (props) => {
    const [imageLoaded, setImageLoaded] = React.useState<boolean>(false);

    let url = `https://maps.googleapis.com/maps/api/staticmap?center=${props.latitude},${props.longitude}&zoom=${props.zoomLevel}&size=${props.width}x${props.height}&key=${process.env.REACT_APP_Google_Map_Api_Key}`;
    if (props.markers && props.markers.length > 0) {
        props.markers.forEach(m => {
            url = `${url}&markers=color:${m.colour??'red'}%7C${m.latitude},${m.longitude}`
        });
    }

    const imageProps = {
        src: url
    }

    return (<>
                <Box sx={{ visibility: imageLoaded ? 'visible' : 'hidden'}} width={props.width} height={props.height}>
                    <img {...imageProps} onLoad={() => setImageLoaded(true)} />
                </Box>
            </>)
}

export default GoogleStaticMap;