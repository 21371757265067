import React, { useState } from 'react';
import { FileDto } from '../../api/useFileSystem';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import FormHelperText from '@mui/joy/FormHelperText';
import { CollaboratorDto, useDeleteCollaborator } from '../../api/useCollaborators';


export interface IDeleteCollaboratorDialog{
    projectId?: string | undefined,    
    collaborator: CollaboratorDto,
    isOpen: boolean,
    onClose: ()=>void
}

export default function(props: IDeleteCollaboratorDialog){
    const [formError, setFormError] = useState<string>();
    const deleteCollaborator = useDeleteCollaborator();

    function onDelete(){    
        async function doWork(){
            if(props.collaborator.id){
                const result = await deleteCollaborator({projectId: props.projectId!, collaboratorId: props.collaborator.id!});                
                if(result.succeeded && result.data?.assignedToActions){
                    setFormError("The Collaborator is still assigned to Action Items")
                }
                else if(result.succeeded && result.data?.success){
                    props.onClose();
                }
            }
            else    
                setFormError("The risk has not been saved");
        }
        doWork();
    }

    if(!props.collaborator)
        return <></>

    return (
        <Modal open={props.isOpen} onClose={props.onClose}>
            <ModalDialog onClick={(e)=>e.stopPropagation()}>
                <Stack direction={'column'} gap={3}>
                    <Typography>Do you want to delete collaborator {props.collaborator.name}?</Typography>                    
                    <Button onClick={()=> onDelete()}>Delete</Button>
                    {!!formError && <FormHelperText>
                                {formError}
                            </FormHelperText>}
                </Stack>
            </ModalDialog>
        </Modal>
    )
}