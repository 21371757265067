import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { ProjectClient, 
    ProjectDto, 
    SaveProjectCommand, 
    Result, 
    AssignProjectUsersCommand, 
    ProjectUserMapping,
    ProjectPhaseDto,
    SaveProjectPhaseCommand
} from './api.generated.clients';

interface IQueryResponse<T> {
    isPending: boolean,
    data: T | undefined,
    error: Error
}

export interface IProjectApi{
    getProjectList: () => IQueryResponse<ProjectDto[]>,
    getProject: (id:string) => IQueryResponse<ProjectDto>,
    saveProject: (command: SaveProjectCommand) => Promise<boolean>,
    deleteProject: (projectId: string) => Promise<Result>
}
    
    const projectClient = new ProjectClient();    

    const useSaveProject =  () =>{
        const queryClient = useQueryClient();
        const saveProjectMutation = useMutation({
            mutationFn: (project: SaveProjectCommand ) => {
                return projectClient.projectPost(project)
            },
            onSuccess: (data, variables)=>{
                queryClient.invalidateQueries({queryKey: ['project']});
            }
        });
        
        return saveProjectMutation.mutateAsync;        
    }

    const useAssignProjectUsers = () => {
        const queryClient = useQueryClient();
        const assignUsersMutation = useMutation({
            mutationFn: (args : AssignProjectUsersCommand) =>  projectClient.permissions(args),
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries({queryKey: ['project', variables.targetProjectId]});                
            }
        })

        return assignUsersMutation.mutateAsync;
    }

    const useGetProjects = ()=>{
        return useQuery({ queryKey: ['project'], queryFn: () => projectClient.list() });
    }

    const useGetProject = (id: string) => {
        return useQuery({ 
            queryKey: ['project', id], 
            queryFn: ()=> projectClient.projectGet(id),
            enabled: !!id && id !== ''
        })        
    }    

    const useDeleteProject = () => {
        const queryClient = useQueryClient();
        const deleteProjectMutation = useMutation({
            mutationFn: ( projectId: string ) => projectClient.projectDelete(projectId),
            onSuccess: (data, variables)=> {
                queryClient.invalidateQueries({ queryKey: ['project']})
            }
        });
        return deleteProjectMutation.mutateAsync;
    }

    const useSavePhase = () => {
        const queryClient = useQueryClient();
        const savemutation = useMutation({
            mutationFn: (cmd: SaveProjectPhaseCommand) => projectClient.phasePost(cmd),
            onSuccess: (data, variables) => {
                queryClient.invalidateQueries({ queryKey: ['project']})
            }
        });
        return savemutation.mutateAsync;
    }

    const useDeletePhase = () => {
        const queryClient = useQueryClient();
        const deleteMutation = useMutation({
            mutationFn: (props:{projectId:string, phaseId:string})=> projectClient.phaseDelete(props.projectId, props.phaseId),
            onSuccess:(data, variables) => {
                queryClient.invalidateQueries({ queryKey: ['project']})
            }
        });
        return deleteMutation.mutateAsync;
    }

    // return {
    //     getProjectList: getProjects,
    //     saveProject: saveProject,
    //     getProject: getProject,
    //     deleteProject: deleteProject
    // } as IProjectApi

export type { SaveProjectCommand, AssignProjectUsersCommand, ProjectUserMapping, ProjectDto, ProjectPhaseDto, SaveProjectPhaseCommand };
export { useGetProjects, useSaveProject, useGetProject, useDeleteProject, useAssignProjectUsers, useSavePhase, useDeletePhase}