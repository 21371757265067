import React from 'react'
import {Stack, Button, Modal, ModalDialog, Typography, Input, Textarea, Select, Option} from '@mui/joy';
import { useSaveCollaborator, SaveCollaboratorCommand } from '../../api/useCollaborators';
import AddIcon from '@mui/icons-material/Add';

export interface IInviteCollaboratorButton{
    projectId: string,
    
}

export default function(props: IInviteCollaboratorButton){
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [saveCollaboratorCommand, setSaveCollaboratorCommand] = React.useState<SaveCollaboratorCommand>({});
    const [showEmailExistsMessage, setShowEmailExistsMessage] = React.useState<boolean>(false);
    const saveCollaborator = useSaveCollaborator();

    function onSave(){
        async function doWork(){
            var cmd = {...saveCollaboratorCommand, projectId: props.projectId} as SaveCollaboratorCommand;
            if(!cmd.name || cmd.name.length == 0)
                return;

            const result = await saveCollaborator(cmd);
            if(result.emailAlreadyExists){
                setShowEmailExistsMessage(true);
            }
            else if(result.success){
                setSaveCollaboratorCommand({});
                setIsOpen(false);
            }
        }
        doWork();
    }

    return <Stack direction='row' sx={{ width: '100%'}}>
        <Button sx={{ width: '100%'}} variant='soft' onClick={()=>setIsOpen(true)} startDecorator={<AddIcon/>}>Invite Collaborator</Button>
        <Modal open={isOpen} onClose={() => setIsOpen(false)} >
        <ModalDialog sx={{ width: '800px' }}>
            <Stack direction={'column'} spacing={2} sx={{ overflow: 'auto' }}>
                <Stack spacing={2} direction={'column'} pr={5}>
                    <Typography mb={5} level={'h2'}>Invite Collaborator</Typography>
                    <Typography>Full Name</Typography>
                    <Input value={saveCollaboratorCommand.name} onChange={(e) => setSaveCollaboratorCommand({ ...saveCollaboratorCommand, name: e.target.value })} />
                    <Typography>Email</Typography>
                    <Typography level='body-sm'>If you include an email, then this person will be emailed notifications/reminders</Typography>
                    <Input value={saveCollaboratorCommand.email} onChange={(e) => setSaveCollaboratorCommand({ ...saveCollaboratorCommand, email: e.target.value })} />
                    <Typography>Company</Typography>
                    <Input value={saveCollaboratorCommand.company} onChange={(e) => setSaveCollaboratorCommand({ ...saveCollaboratorCommand, company: e.target.value })} />
                    <Typography>Notes</Typography>
                    <Textarea minRows={4} maxRows={10} value={saveCollaboratorCommand.notes} onChange={(event) => setSaveCollaboratorCommand({ ...saveCollaboratorCommand, notes: event.target.value })}></Textarea>
                    {showEmailExistsMessage && <Typography color='warning'>There is already a collaborator that exists with this email</Typography>}

                    <Stack direction='row' justifyContent={'space-between'} sx={{ width: '100%' }}>
                        <Button onClick={() => setIsOpen(false)} variant='soft'>Cancel</Button>
                        <Button onClick={() => onSave()}>Submit</Button>
                    </Stack>
                </Stack>
            </Stack>

        </ModalDialog>
    </Modal>
    </Stack>
}