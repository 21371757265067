import React, { ReactElement } from 'react'
import Stack from '@mui/joy/Stack';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import { useGetUsersList } from '../../api/useUserAdmin';
import { GetUserItemDto } from '../../api/api.generated.clients';
import AddUserButton from '../../components/Users/AddUserButton';
import UserContextMenu from './UserContextMenu';




export default function () {
    const [users, setUsers] = React.useState<GetUserItemDto[]>([])
    const userQuery = useGetUsersList();

    React.useEffect(()=>{
        if(!userQuery.isPending && !userQuery.error && userQuery.data)
            setUsers(userQuery.data);
    },[userQuery.isPending, userQuery.data, userQuery.error]);

    const tableStyle  = {
        maxWidth: '900px',
        '& th' : { textAlign: 'left'},
        '& td' : { textAlign: 'left' }
    } as React.CSSProperties

    return (
        
            <>
                <Stack direction={'row'} justifyContent={'flex-start'} sx={{ mb: '5px'}}>
                    <Typography level={'h2'}>User Management</Typography>
                    <AddUserButton/>
                </Stack>
                <Table borderAxis='both' sx={tableStyle}>
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Roles</th>
                            <th style={{ width: '80px'}}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.filter(u=>!!u.userId).map(u=>{
                            return (<tr>
                                <td>{u.email}</td>
                                <td>{u.firstName} {u.lastName}</td>
                                <td>{u.roles}</td>
                                <td><UserContextMenu userId={u.userId!}/></td>
                            </tr>)
                        })}
                    </tbody>
                </Table>
                </>
            
    )
}