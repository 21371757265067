import RoomIcon from '@mui/icons-material/RoomSharp';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import React from 'react';
import { useDeleteFilePoint } from '../../api/useRisks';
import { useDeleteFilePoint as useDeleteOpportunityFilePoint } from '../../api/useSustainability';
import SelectedRiskMarkerOverlay from '../Risks/SelectedRiskMarkerOverlay';
import OpportunityMarkerOverlay from '../Sustainability/OpportunityMarkerOverlay';

export interface IMarkerProps {
    id?: string | undefined,
    itemId: string,
    text?: string | undefined,
    x: number,
    y: number,
    width?: number | undefined,
    height?: number | undefined
}

export interface ISystemMarkerProps extends IMarkerProps {
    zoomScale: number,
    showOverlay: boolean
    onClick?: (()=>void) | undefined,
    colour: string,
    showAlternateMarker: boolean
}

interface IPoint{
    x: number, 
    y: number
}

export default function (props: ISystemMarkerProps) {
    const [topLeft, setTopLeft] = React.useState<IPoint>({x:props.x, y:props.y} as IPoint);
    const deleteFileMarker = useDeleteFilePoint();
    const deleteOpportunityFileMarker = useDeleteOpportunityFilePoint();

    React.useEffect(()=>{
        setTopLeft({x:props.x, y: props.y});
    },[props.x, props.y])    

    const styles: React.CSSProperties = {
        position: 'absolute',
        left: (topLeft.x * props.zoomScale - 35) + 'px',
        top: (topLeft.y * props.zoomScale - 55) + 'px',
        padding: '10px',
        borderRadius: '5px',
        userSelect: 'none',
        width: '48px',
        height: '48px',
        color: props.colour ?? 'red'
    }

    const overlayStyles : React.CSSProperties = {
        left: 'calc(' + styles.left + ' + ' + styles.width + ')',
        top: 'calc(' + styles.top + ' + ' + styles.height + ')'
    }
    
    function onDeleteRiskMarker(){
        async function doWork(){
            const result = await deleteFileMarker({riskId: props.itemId, filepointId: props.id!});
        }
        doWork();
    }

    function onDeleteOpportunityMarker(){
        async function doWork(){
            const result = await deleteOpportunityFileMarker({opportunityId: props.itemId, filepointId: props.id!});
        }
        doWork();
    }

    return (
        <>
            {props.showAlternateMarker ? 
                <AddLocationIcon style={styles} onClick={()=> props.onClick?.()}/> : 
                <RoomIcon style={styles} onClick={()=> props.onClick?.()}/>}

            {props.showOverlay && ( props.showAlternateMarker ? 
                <OpportunityMarkerOverlay styles={overlayStyles} title={props.text} onDeleteMarker={onDeleteOpportunityMarker}/>  : 
                <SelectedRiskMarkerOverlay styles={overlayStyles} title={props.text} onDeleteMarker={onDeleteRiskMarker}/>)}
        </>        
    
    )
}

