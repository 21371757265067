import React from 'react';
import * as markerjs2 from 'markerjs2';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import { FileDto, SaveImageAnnotationCommand } from '../../api/useFileSystem';
import { render } from '@testing-library/react';
import { Typography } from '@mui/joy';

export interface ImageFileViewerProps {
  phaseId: string,
  file: FileDto | undefined
}

export default function (props: ImageFileViewerProps) {
  const imgRef = React.createRef<HTMLImageElement>();

  React.useEffect(()=>{
    if(props.file?.imageAnnotation && imgRef.current){
      const markerArea = new markerjs2.MarkerArea(imgRef.current);
      markerArea.targetRoot = imgRef.current;
      
      const renderHandler = (event:markerjs2.MarkerAreaRenderEvent) => 
      {
        if (imgRef.current) {
          imgRef.current.src = event.dataUrl;
        }
      }
      markerArea.addEventListener('render', renderHandler);
      markerArea.show();

      if (props.file?.imageAnnotation?.annotationData){
        setTimeout(() => {
          markerArea.renderState(JSON.parse(props.file!.imageAnnotation!.annotationData!))
        }, 300);
        
      }

      return ()=>{
        markerArea.removeEventListener('render', renderHandler);
      }
    }
  },[props.file])

  function showMarkerArea() {
    if (imgRef.current !== null && imgRef.current.parentElement) {
      // create a marker.js MarkerArea
      const markerArea = new markerjs2.MarkerArea(imgRef.current);
      markerArea.targetRoot = imgRef.current?.parentElement;
      markerArea.settings.displayMode = 'popup';
      markerArea.availableMarkerTypes = markerArea.ALL_MARKER_TYPES;
      markerArea.uiStyleSettings.redoButtonVisible = true;
      markerArea.uiStyleSettings.notesButtonVisible = true;
      markerArea.uiStyleSettings.zoomButtonVisible = true;
      markerArea.uiStyleSettings.zoomOutButtonVisible = true;
      markerArea.uiStyleSettings.clearButtonVisible = true;
      markerArea.renderAtNaturalSize = true;

      // attach an event handler to assign annotated image back to our image element
      markerArea.addEventListener('render', event => {
        if (imgRef.current) {
          imgRef.current.src = event.dataUrl;
        }

        var stateText = JSON.stringify(event.state);
        SaveImageAnnotation({ projectId: props.phaseId, fileId: props.file?.id, annotationData: stateText } as SaveImageAnnotationCommand)
      });
      // launch marker.js
      markerArea.show();

      if (props.file?.imageAnnotation?.annotationData){
        markerArea.restoreState(JSON.parse(props.file?.imageAnnotation?.annotationData))
      }
    }
  }

  return (
    <Stack justifyContent={'center'} alignItems={'center'} sx={{ height: 'calc(100% - 20px)' }}>
      <Typography>Click on image to annotate</Typography>
      {props.file && <>
      <img crossOrigin='anonymous' src={props.file!.url} ref={imgRef} onClick={() => showMarkerArea()} />
      </>}
    </Stack>
  )
}

function SaveImageAnnotation(arg0: SaveImageAnnotationCommand) {
  throw new Error('Function not implemented.');
}
