import React, { useState } from 'react';
import { FolderDto } from '../../api/useFileSystem';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import {useDeleteFolder} from '../../api/useFileSystem';
import FormHelperText from '@mui/joy/FormHelperText';


export interface IDeleteFolderDialog{
    phaseId: string,    
    folder: FolderDto,
    isOpen: boolean,
    onClose: ()=>void
}

export default function(props: IDeleteFolderDialog){
    const [formError, setFormError] = useState<string>();
    const DeleteFolder = useDeleteFolder();

    function onDelete(){    
        async function doWork(){
            var result = await DeleteFolder({ projectId:props.phaseId, folderId:props.folder.id!});
            if(result.succeeded)
                props.onClose();
            else{
                setFormError(result.errors?.join('. '));
            }
        }
        doWork();
    }

    return (
        <Modal open={props.isOpen} onClick={(e)=>e.stopPropagation()} onClose={props.onClose}>
            <ModalDialog>
                <Stack direction={'column'} gap={3}>
                    <Typography>Do you want to delete folder {props.folder.name}?</Typography>                    
                    <Button onClick={()=> onDelete()}>Delete</Button>
                    {!!formError && <FormHelperText>
                                {formError}
                            </FormHelperText>}
                </Stack>
            </ModalDialog>
        </Modal>
    )
}